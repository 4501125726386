import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import cs from 'classnames';
import {IconProps} from '../type';
import { WithStyles, createStyles } from '@material-ui/core/styles';

const styles = () => createStyles({
  root: {
    width: 10,
    height: 18
  }
});

export interface Props extends IconProps, WithStyles<typeof styles>{

}

const FacebookIcon = (props: Props) => {
  const {classes, className} = props;
  return(
    <SvgIcon className={cs(classes.root, className)} viewBox="0 0 10 18">
      <path d="M7.781 2.989H9.46V.125A22.264 22.264 0 0 0 7.014 0c-2.42 0-4.077 1.491-4.077 4.23v2.36H.2v3.2h2.737V18H6.21V9.79h2.73l.41-3.2H6.21V4.546c0-.927.255-1.558 1.571-1.558" fill="#212121" fillRule="evenodd"/>
    </SvgIcon>
  )
}

export default withStyles(styles)(FacebookIcon);
