import * as React from 'react'
import cs from 'classnames';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core/styles';

export interface Props {
  static?: boolean,
  className?: string,
  children: React.ReactNode
};

const styles = (theme: Theme) => createStyles({
  root: {
    boxSizing: 'border-box',
    margin: 0,
    width: '100%',
    paddingLeft: 12,
    paddingRight: 12,
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 12,
      paddingRight: 12
    },
    [theme.breakpoints.up('md')]: {
      margin: '0 auto',
      paddingLeft: 28,
      paddingRight: 28,
      maxWidth: 712,
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 62,
      paddingRight: 62,
      maxWidth: '100%',
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: 72,
      paddingRight: 72,
      maxWidth: '100%',
    }
  },
});

const JnJWelContainer = (props: Props & WithStyles) => {
  const { classes } = props;
  return (
    <div className={cs(classes.root, props.className)}>
      {props.children}
    </div>
  )
}

export default withStyles(styles)(JnJWelContainer);
