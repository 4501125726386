import {Reducer} from 'redux';
import * as actions from './action';
import { getType, ActionType } from 'typesafe-actions';
import { FooterData } from './type';

type State =  {
  readonly footerData?: FooterData,
  readonly loading: boolean
}

type Actions = ActionType<typeof actions>;

const initialState: State = {
  loading: true,
};

export const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch(action.type) {
    case getType(actions.fetchFooter.request):
      return {
        ...state,
        loading: true
      };
    case getType(actions.fetchFooter.success):
      return {
        ...state,
        footerData: action.payload,
        loading: false
      };
    case getType(actions.fetchFooter.failure):
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
