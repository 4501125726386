import { AxiosInstance, AxiosResponse } from "axios";
import { BlockDTO, BlocksResponseDTO, DrupalHtmlDTO, IQueryParams } from "./types";
import { IPageHeading } from "../../type";

type HeadingBlockDTO = BlockDTO<'node--component_simple', {
  field_header_rich: {
    value: string
  };
  field_component_description: {
    processed: string
  }
}>;

type QueryParams = IQueryParams<{
  'field_component_page': string;
  'field_weight'?: number;
}>

type IPageHeadingInnerParams = Partial<Pick<IPageHeading, 'bgColor' | 'padding' | 'pageHeading'>>;

function parseHeadingBlock(response: AxiosResponse<BlocksResponseDTO<HeadingBlockDTO>>, innerParams?: IPageHeadingInnerParams): IPageHeading {
  const [blockData] = response.data.data;
  return {
    type: 'PageHeading',
    data: {
      title: blockData.attributes.field_header_rich.value,
      body: blockData.attributes.field_component_description.processed,
    },
    ...innerParams,
  };
}


export async function fetchHeadingBlock(axiosInstance: AxiosInstance, queryParams?: QueryParams, innerParams?: IPageHeadingInnerParams): Promise<IPageHeading> {

  const blockResponse = await axiosInstance.get<BlocksResponseDTO<HeadingBlockDTO>>('/node/component_simple', {
    params: {
      ...queryParams,
    },
  });

  return parseHeadingBlock(blockResponse, innerParams);
}
