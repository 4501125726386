import { AxiosInstance, AxiosResponse } from "axios";
import { BlockDTO, BlocksResponseDTO, ImageBlockDTO } from "./types";
import { ComponentWithHeading } from "../../type";

type InnovationHistoryBlockDTO = BlockDTO<'node--component_time_line', {
  field_header: string;
  field_component_description: {
    processed: string
  }
}>;

type YearInfoBlockDTO = BlockDTO<'paragraph--component_time_line_item', {
  field_component_title: string,
  field_component_year: number,
}>;


function parseInnovationHistoryBlock(response: AxiosResponse<
  BlocksResponseDTO<InnovationHistoryBlockDTO, YearInfoBlockDTO>>): ComponentWithHeading {
  const {
    data: [ blockData ],
    included: paragraphsData,
   } = response.data;

  return {
    type: 'ComponentWithHeading',
    data: {
      title: blockData.attributes.field_header,
      description: blockData.attributes.field_component_description.processed,
    },
    component: {
      type: "InnovationHistory",
      data: paragraphsData.map(({attributes}) => ({
        year: attributes.field_component_year,
        info: attributes.field_component_title,
      })),
    },
  };
}

export async function fetchInnovationHistoryBlock(axiosInstance: AxiosInstance, pageId: string): Promise<ComponentWithHeading> {
  const blockResponse = await axiosInstance.get
  <BlocksResponseDTO<InnovationHistoryBlockDTO, YearInfoBlockDTO>>('/node/component_time_line', {
    params: {
      filter: {
        'field_component_page': pageId,
      },
      include: [
        'field_component_time_line_items',
      ].join()
    },
  });

  return parseInnovationHistoryBlock(blockResponse);
}
