import axiosInstance, { requestUrl } from './api';
import { BlockDTO, BlocksResponseDTO } from './fetchBlock/types';
import { LoginData } from '../store/auth/reducer';

export type LoginDataDTO = BlockDTO<'block_content--login_block', {
  field_heading: string,
  field_body: {
    value: string
  },
  field_cta_label: string,
  field_register_label: string,
  field_items_list: string[],
  field_jnj_user_login_label: string,
  field_jnj_user_login_text: {
    value:string
  },
  user_flow: {
    forgot_password_flow:{
      form_forgot_password: {
        button: string,
        cancel: string,
        header: string,
        header_sub: string,
        placeholders: {
          email: string,
        },
        validation: {
          email_not_valid: string,
          user_not_exist: string,
          captcha: string,
        }
      },
      form_reset_password: {
        button: string,
        cancel: string,
        header: string,
        header_sub: string,
        placeholders: {
          password: string,
          password_repeat: string,
        },
        validation: {
          password_not_match: string,
          password_not_strong: string,
        }
      },
      response_forgot_password: {
        header: string,
        header_sub: string,
      },
      response_link_expired: {
        header: string,
        button: string,
        header_sub: string,
      },
      response_reset_success: {
        button: string,
        header: string,
        header_sub: string,
      }
    },
    login_flow: {
      form: {
        button: string,
        forgot_password: string,
        header: string,
        cancel: string,
        placeholders: {
          email: string,
          password: string,
        }
        validation: {
          credentials_incorrect: string,
          captcha: string,
          email_not_valid: string,
          empty_password: string,
          maximun_login_attempts: string,
        }
      }
    },
    registration_flow: {
      form: {
        button: string,
        cancel: string,
        header: string,
        header_sub: string,
        processing: string,
        placeholders: {
          email: string,
          password: string,
          password_repeat: string,
        }
        validation: {
          email_not_valid: string,
          password_not_match: string,
          password_not_string: string,
          captcha: string,
        },
      },
      response_success: {
        header: string,
        header_sub: string,
      },
      response_user_already_exist: {
        button_forgot_password: string,
        button_sign_in: string,
        header: string,
        header_sub: string,
      },
      response_user_not_exist: {
        header: string,
        button: string,
        header_sub: string,
      },
      response_user_verification_expired: {
        button: string,
        header: string,
        header_sub: string,
      },
      response_user_verification_success: {
        button: string,
        header: string,
        header_sub: string,
      },
    }
  }
}>

export type LoginIncludedDTO = BlockDTO<'file--file', {
  uri: {
    url: string
  }
}>

export async function loadLogin(): Promise<LoginData> {
  const res = await axiosInstance.get<BlocksResponseDTO<LoginDataDTO,
  LoginIncludedDTO>>(`block_content/login_block`, {
    params: {
      include: ['field_images'].join(),
    }
  });

  const {
    data: [ blockData ],
    included: [paragraphsData],
  } = res.data;

  return {
    loginPage: {
      title: blockData.attributes.field_heading,
      description: blockData.attributes.field_body.value ?
        blockData.attributes.field_body.value :
        '',
      labelCTA: blockData.attributes.field_cta_label,
      imgSrc: requestUrl + paragraphsData.attributes.uri.url,
      list: blockData.attributes.field_items_list,
      registerLabelCTA: blockData.attributes.field_register_label,
      jnjUserCTA: blockData.attributes.field_jnj_user_login_label,
      jnjUserText: blockData.attributes.field_jnj_user_login_text ?
        blockData.attributes.field_jnj_user_login_text.value :
        'J&J Users:',
    },
    forgotPasswordForm: {
      buttonText: blockData.attributes.user_flow.forgot_password_flow.form_forgot_password.button,
      cancelButtonText: blockData.attributes.user_flow.forgot_password_flow.form_forgot_password.cancel,
      headerText: blockData.attributes.user_flow.forgot_password_flow.form_forgot_password.header,
      description: blockData.attributes.user_flow.forgot_password_flow.form_forgot_password.header_sub,
      placeholder: blockData.attributes.user_flow.forgot_password_flow.form_forgot_password.placeholders.email,
      validation: blockData.attributes.user_flow.forgot_password_flow.form_forgot_password.validation,
    },
    resetPasswordForm: {
      buttonText: blockData.attributes.user_flow.forgot_password_flow.form_reset_password.button,
      cancelButtonText: blockData.attributes.user_flow.forgot_password_flow.form_reset_password.cancel,
      headerText: blockData.attributes.user_flow.forgot_password_flow.form_reset_password.header,
      description: blockData.attributes.user_flow.forgot_password_flow.form_reset_password.header_sub,
      placeholders: blockData.attributes.user_flow.forgot_password_flow.form_reset_password.placeholders,
      validation: blockData.attributes.user_flow.forgot_password_flow.form_reset_password.validation
    },
    responseForgotPassword: {
      headerText: blockData.attributes.user_flow.forgot_password_flow.response_forgot_password.header,
      description: blockData.attributes.user_flow.forgot_password_flow.response_forgot_password.header_sub,
    },
    responseLinkExpired: {
      headerText: blockData.attributes.user_flow.forgot_password_flow.response_link_expired.header,
      description: blockData.attributes.user_flow.forgot_password_flow.response_link_expired.header_sub,
      buttonText: blockData.attributes.user_flow.forgot_password_flow.response_link_expired.button,
    },
    responseResetSuccess: {
      headerText: blockData.attributes.user_flow.forgot_password_flow.response_reset_success.header,
      description: blockData.attributes.user_flow.forgot_password_flow.response_reset_success.header_sub,
      buttonText: blockData.attributes.user_flow.forgot_password_flow.response_reset_success.button,
    },
    loginForm: {
      buttonText: blockData.attributes.user_flow.login_flow.form.button,
      cancelButtonText: blockData.attributes.user_flow.login_flow.form.cancel,
      forgotPasswordText: blockData.attributes.user_flow.login_flow.form.forgot_password,
      headerText: blockData.attributes.user_flow.login_flow.form.header,
      placeholders: blockData.attributes.user_flow.login_flow.form.placeholders,
      validation: blockData.attributes.user_flow.login_flow.form.validation,
    },
    registrationForm: {
      buttonText: blockData.attributes.user_flow.registration_flow.form.button,
      cancelButtonText: blockData.attributes.user_flow.registration_flow.form.cancel,
      headerText: blockData.attributes.user_flow.registration_flow.form.header,
      description: blockData.attributes.user_flow.registration_flow.form.header_sub,
      placeholders: blockData.attributes.user_flow.registration_flow.form.placeholders,
      validation: blockData.attributes.user_flow.registration_flow.form.validation,
      processing: blockData.attributes.user_flow.registration_flow.form.processing,
    },
    registrationResponseSuccess: {
      headerText: blockData.attributes.user_flow.registration_flow.response_success.header,
      description: blockData.attributes.user_flow.registration_flow.response_success.header_sub,
    },
    registrationResponseUserExist: {
      headerText: blockData.attributes.user_flow.registration_flow.response_user_already_exist.header,
      buttonText: blockData.attributes.user_flow.registration_flow.response_user_already_exist.button_sign_in,
      secondButtonText: blockData.attributes.user_flow.registration_flow.response_user_already_exist.button_forgot_password,
      description: blockData.attributes.user_flow.registration_flow.response_user_already_exist.header_sub,
    },
    registrationResponseUserNotExist: {
      buttonText: blockData.attributes.user_flow.registration_flow.response_user_not_exist.button,
      headerText: blockData.attributes.user_flow.registration_flow.response_user_not_exist.header,
      description: blockData.attributes.user_flow.registration_flow.response_user_not_exist.header_sub
    },
    registrationResponseUserVerExpired: {
      buttonText: blockData.attributes.user_flow.registration_flow.response_user_verification_expired.button,
      headerText: blockData.attributes.user_flow.registration_flow.response_user_verification_expired.header,
      description: blockData.attributes.user_flow.registration_flow.response_user_verification_expired.header_sub,
    },
    registrationResponseUserVerSuccess: {
      buttonText: blockData.attributes.user_flow.registration_flow.response_user_verification_success.button,
      headerText: blockData.attributes.user_flow.registration_flow.response_user_verification_success.header,
      description: blockData.attributes.user_flow.registration_flow.response_user_verification_success.header_sub,
    }
  }
}
