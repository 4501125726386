import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';

class ScrollToTop extends React.Component<RouteComponentProps> {
  componentDidUpdate(prevProps: RouteComponentProps) {
    const { location } = this.props;
    const { location: oldLocation } = prevProps;

    if (
      location !== oldLocation &&
      (!location.state || !location.state.doNotScroll)
    ) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
