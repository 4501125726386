import {Reducer} from 'redux';
import { getType, ActionType } from 'typesafe-actions';
import * as actions from './action';
import { UserData, LoginResponse } from '../../services/auth';
import Cookies from 'js-cookie';

export type RegisterStatus = 'none' | 'success' | 'user-exist' | 'user-not-exist';
export type ForgotPasswordStatus = 'none' | 'ok' | 'no_such_account';
export type NewPasswordStatus = 'none' | 'ok' | 'invalid_access_token';

type State =  {
  isLogged: boolean;
  registerStatus: RegisterStatus;
  forgotPasswordStatus: ForgotPasswordStatus;
  newPasswordStatus: NewPasswordStatus;
  isLoading: boolean;
  userData?: UserData;
  loginPageData: LoginPageData | null;
  forgotPasswordForm: ForgotPasswordData | null;
  resetPasswordForm: any;
  responseForgotPassword: any;
  responseLinkExpired: any;
  responseResetSuccess: any;
  registrationResponseSuccess: any;
  registrationResponseUserExist: any;
  registrationResponseUserVerSuccess: any;
  registrationResponseUserVerExpired: any;
  registrationResponseUserNotExist: any;
  loginForm: any;
  registrationForm: any;
  loading: boolean;
  error?: string;
}

type Actions = ActionType<typeof actions>;

export interface LoginData {
  loginPage: LoginPageData,
  forgotPasswordForm: ForgotPasswordData,
  resetPasswordForm: any,
  responseResetSuccess: any,
  responseForgotPassword: any,
  responseLinkExpired: any,
  registrationForm: any,
  registrationResponseSuccess: any,
  registrationResponseUserExist: any,
  registrationResponseUserVerExpired: any,
  registrationResponseUserNotExist: any,
  registrationResponseUserVerSuccess: any,
  loginForm: any,
}

export interface LoginPageData {
  title: string,
  description: string,
  imgSrc: string,
  labelCTA: string,
  registerLabelCTA: string,
  jnjUserCTA: string,
  jnjUserText: string,
  list: string[],
}

export interface ForgotPasswordData {
  buttonText: string,
  cancelButtonText: string,
  headerText: string,
  description: string,
  placeholder: string,
  validation: {
    email_not_valid: string,
    user_not_exist: string,
  }
}

const initialState: State = {
  isLogged: JSON.parse(localStorage.getItem('isLogged') || "false") as boolean,
  registerStatus: 'none',
  forgotPasswordStatus: 'none',
  newPasswordStatus: 'none',
  isLoading: false,
  loginPageData: null,
  forgotPasswordForm: null,
  resetPasswordForm: null,
  responseForgotPassword: null,
  responseLinkExpired: null,
  responseResetSuccess: null,
  registrationResponseSuccess: null,
  registrationResponseUserExist: null,
  registrationResponseUserVerExpired: null,
  registrationResponseUserVerSuccess: null,
  registrationResponseUserNotExist: null,
  registrationForm: null,
  loginForm: null,
  loading: false,
};

const ACCESS_TOKEN_KEY = 'access_token';
export function clearAuthData() {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
}

export function storeAuthData(response: LoginResponse) {
  localStorage.setItem(ACCESS_TOKEN_KEY, JSON.stringify(response));
}

export function restoreAuthData(): LoginResponse | null {
  const authDataJSON = localStorage.getItem(ACCESS_TOKEN_KEY);
  let response = authDataJSON !== null
    ? JSON.parse(authDataJSON) as LoginResponse
    : null;

  if (response && (response.expiresIn) <= (new Date()).getTime()) {
    response = null;
    localStorage.removeItem(ACCESS_TOKEN_KEY);
  }
  return response;
}

export const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch(action.type) {
    case getType(actions.restoreAuth):
      const restoredAuthData = restoreAuthData();
      return restoredAuthData !== null
        ? {
          ...state,
          isLogged: true,
          userData: restoredAuthData!.userData,
        }
        : state;

    case getType(actions.signUp.success):
      return {
        ...state,
        registerStatus: 'success',
      };

    case getType(actions.signUp.failure):
      return {
        ...state,
        registerStatus: action.payload as RegisterStatus,
      };

    case getType(actions.forgotPassword.success):
      return {
        ...state,
        forgotPasswordStatus: 'ok',
      };

    case getType(actions.forgotPassword.failure):
      return {
        ...state,
        forgotPasswordStatus: action.payload === 'no_such_account' ?  'no_such_account' : 'none',
      };

      case getType(actions.setNewPassword.success):
      return {
        ...state,
        newPasswordStatus: 'ok',
      };

    case getType(actions.setNewPassword.failure):
      return {
        ...state,
        newPasswordStatus: action.payload === 'invalid_access_token' ?  'invalid_access_token' : 'none',
      };

    case getType(actions.cleanUp):
      return {
        ...state,
        forgotPasswordStatus:  'none',
      };

    case getType(actions.clearRegisterStatus):
      return {
        ...state,
        registerStatus: 'none',
      };

    case getType(actions.login.request):
      return {
        ...state,
        isLoading: true,
      };

    case getType(actions.login.success):
      storeAuthData(action.payload);
      return {
        ...state,
        isLogged: true,
        userData: action.payload.userData,
        isLoading: false,
      };
    case getType(actions.login.failure):
      return {
        ...state,
        isLoading: false,
      };
    case getType(actions.logout):
      clearAuthData();

      const hostname = window && window.location && window.location.hostname;
      // remove the cookie timestamp from server (backend)
      Cookies.remove('jnj_welcome_saml', { domain: '.' + hostname });
      Cookies.remove('jnj_welcome_saml', { domain: hostname });
      Cookies.remove('jnj_welcome_saml_givenname', { domain: '.' + hostname });
      Cookies.remove('jnj_welcome_saml_givenname', { domain: hostname });
      Cookies.remove('jnj_welcome_saml_sn', { domain: '.' + hostname });
      Cookies.remove('jnj_welcome_saml_sn', { domain: hostname });

      return {
        ...state,
        isLogged: false,
      };
    case getType(actions.fetchLogin.request):
      return {
        ...state,
        loading: true,
      };
    case getType(actions.fetchLogin.success):
      return {
        ...state,
        loginPageData: action.payload.loginPage,
        forgotPasswordForm: action.payload.forgotPasswordForm,
        resetPasswordForm: action.payload.resetPasswordForm,
        responseForgotPassword: action.payload.responseForgotPassword,
        responseResetSuccess: action.payload.responseResetSuccess,
        responseLinkExpired: action.payload.responseLinkExpired,
        loginForm: action.payload.loginForm,
        registrationResponseSuccess: action.payload.registrationResponseSuccess,
        registrationResponseUserExist: action.payload.registrationResponseUserExist,
        registrationResponseUserVerExpired: action.payload.registrationResponseUserVerExpired,
        registrationResponseUserVerSuccess: action.payload.registrationResponseUserVerSuccess,
        registrationResponseUserNotExist: action.payload.registrationResponseUserNotExist,
        registrationForm: action.payload.registrationForm,
        loading: false,
        error: undefined,
      };
    case getType(actions.fetchLogin.failure):
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
