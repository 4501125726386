import * as React from 'react';

interface Props {
  content: string | null;
  className?: string;
}

const HtmlSinitizer = (props: Props) => {
  var sanitizeHtml = require('sanitize-html');

  if (props.content === null){
    return null;
  }
  var dirty = props.content;
  var clean = sanitizeHtml(dirty,
    {
      allowedTags:  ['h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol',
      'nl', 'li', 'b', 'i', 'strong', 'span', 'em', 'strike', 'code', 'hr', 'br', 'div',
      'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre', 'img', 'ins', 'u' ],
    },
  );
  return (
    <div className={props.className}
      dangerouslySetInnerHTML={{__html: clean}}
    />
  )
}

export default HtmlSinitizer;
