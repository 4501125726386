import { AxiosInstance, AxiosResponse } from "axios";
import { BlockDTO, BlocksResponseDTO } from "./types";
import { IFirstDayChecklistBlock } from "../../type";


type FirstDayChecklistBlockDTO = BlockDTO<'node--component_check_list', {
  field_header: string;
  field_component_sub_title: string;
  field_component_items_text: string[];
}>;

function parseFirstDayChecklistBlock(response: AxiosResponse<
  BlocksResponseDTO<FirstDayChecklistBlockDTO>>): IFirstDayChecklistBlock {
  const [ blockData ] = response.data.data;
  return {
    type: "FirstDayChecklistBlock",
    data: {
      label: blockData.attributes.field_header,
      subLabel:  blockData.attributes.field_component_sub_title,
      items: blockData.attributes.field_component_items_text,
    }
  };
}

export async function fetchFirstDayChecklistBlock(
  axiosInstance: AxiosInstance, pageId: string): Promise<IFirstDayChecklistBlock> {
  const blockResponse = await axiosInstance.get
  <BlocksResponseDTO<FirstDayChecklistBlockDTO>>('/node/component_check_list', {
    params: {
      filter: {
        'field_component_page': pageId,
      },
    },
  });

  return parseFirstDayChecklistBlock(blockResponse);
}
