import { AxiosInstance, AxiosResponse } from "axios";
import { BlockDTO, BlocksResponseDTO, ImageBlockDTO} from "./types";
import {IInstagramPostsBlock } from "../../type";
import { requestUrl } from "../api";

type InstagramBlockDTO = BlockDTO<'node--component_posts_instagram', {
  field_header: string;
  field_cta_link_title: string;
}>;

type FieldComponentSimpleParagraphDTO = BlockDTO<'paragraph--component_instagram_post', {
  field_component_title: string;
  field_component_link: {
    uri: string
  }
}, {
  field_component_image: {
    data: {
      id: string;
    };
}}>

function parseInstagramBlock(response: AxiosResponse<BlocksResponseDTO<InstagramBlockDTO,
  FieldComponentSimpleParagraphDTO | ImageBlockDTO>>): IInstagramPostsBlock {
  const {
    data: [ blockData ],
    included: paragraphsData,
   } = response.data;

   type AgrType = {
    imageMap: Record<string, ImageBlockDTO>;
    paragraphsBlocks: FieldComponentSimpleParagraphDTO[];
  };

  const {
    imageMap = {},
    paragraphsBlocks = [],
   }: AgrType = paragraphsData.reduce((agr: AgrType, record) => {
      switch(record.type) {
        case 'file--file':
          return {
            ...agr,
            imageMap: {
              ...agr.imageMap,
              [record.id]: record,
            },
          };
        case 'paragraph--component_instagram_post':
          return {
            ...agr,
            paragraphsBlocks: [
              ...agr.paragraphsBlocks,
              record,
            ],
          };
      }
    },
    {
      imageMap: {},
      paragraphsBlocks: [],
    }
  );

  return {
    type: 'InstagramPostsBlock',
    data: {
      title: blockData.attributes.field_header,
      linkText: blockData.attributes.field_cta_link_title,
      items: paragraphsBlocks.map(((item) => ({
        title: item.attributes.field_component_title,
        link: item.attributes.field_component_link.uri,
        imgSrc: requestUrl + imageMap[item.relationships.field_component_image.data.id].attributes.uri.url,
      })))
    }
  }
};

export async function fetchInstagramBlock(axiosInstance: AxiosInstance, pageId: string): Promise<IInstagramPostsBlock> {
  const blockResponse = await axiosInstance.
  get<BlocksResponseDTO<InstagramBlockDTO, FieldComponentSimpleParagraphDTO | ImageBlockDTO>>
  ('/node/component_posts_instagram', {
    params: {
      filter: {
        'field_component_page': pageId,
      },
      include: [
        'field_component_posts',
        'field_component_posts.field_component_image',
      ].join(),
    },
  });

  return parseInstagramBlock(blockResponse);
}
