import {
  combineReducers,
  createStore as reduxCreateStore,
  applyMiddleware,
} from 'redux';
import { reducer as formReducer } from 'redux-form'
import { composeWithDevTools } from 'redux-devtools-extension';
import { all, fork } from 'redux-saga/effects';
import createSagaMiddleware from 'redux-saga';
import { StateType } from 'typesafe-actions';

import * as auth from './auth';
import * as page from './page';
import * as footer from './footer';
import * as header from './header';
import * as cookies from './cookies';
import * as error503 from './503error';

const reducers = {
  footer: footer.reducer,
  auth: auth.reducer,
  page: page.reducer,
  header: header.reducer,
  cookies: cookies.reducer,
  form: formReducer,
  error503: error503.reducer,

};

function* rootSaga() {
  yield all([
    fork(auth.saga),
    fork(page.saga),
    fork(footer.saga),
    fork(header.saga),
    fork(cookies.saga),
  ]);
}

export type RootState = StateType<typeof reducers>;

export default function createStore(state?: RootState) {
  const sagaMiddleware = createSagaMiddleware();
  const store = reduxCreateStore(
    combineReducers(reducers),
    state,
    composeWithDevTools(applyMiddleware(sagaMiddleware))
  );

  sagaMiddleware.run(rootSaga);

  return store;
}
