import * as React from 'react';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core/styles';
import cs from 'classnames';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

export interface Props extends WithStyles<typeof styles> {
  children?: React.ReactNode,
  className?: string,
  xs?: 1 | 2 | 3 | 4 | 0 |'auto'| boolean,
  sm?: 1 | 2 | 3 | 4 | 0 |'auto'| boolean,
  md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 0 |'auto'| boolean,
  lg?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 0 |'auto'| boolean,
  xl?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 0 |'auto'| boolean
}

const GRID_SIZES = {
  xs: 4,
  sm: 4,
  md: 8,
  lg: 10,
  xl: 12
};

function formatStyleKey(breakpoint: Breakpoint, size: true | 'auto' | number) {
  return `grid-${breakpoint}-${size}`
}

function generateGrid(globalStyles: {}, theme: Theme, breakpoint: Breakpoint) {
  const styles = {};

  styles[formatStyleKey(breakpoint, true)] = {
    flexBasis: 0,
    flexGrow: 1,
    maxWidth: '100%',
  };

  styles[formatStyleKey(breakpoint, 'auto')] = {
    flexBasis: 'auto',
    flexGrow: 0,
    maxWidth: 'none',
  };

  styles[formatStyleKey(breakpoint, 0)] = {
    display: 'none'
  };

  for (let index = 1; index <= GRID_SIZES[breakpoint]; index++) {
    let width;
    width = `${Math.round((index / GRID_SIZES[breakpoint]) * 10e7 / 10e5)}%`;
    styles[formatStyleKey(breakpoint, index)] = {
      flexBasis: width,
      flexGrow: 0,
      maxWidth: width,
      display: 'block'
    };
  }

  if (breakpoint === 'xs') {
    Object.assign(globalStyles, styles);
  } else {
    globalStyles[theme.breakpoints.up(breakpoint)] = styles;
  }
}



const styles = (theme: Theme) => createStyles({
  gridPadding: {
    padding: 12
  },
  ...theme.breakpoints.keys.reduce((accumulator, key) => {
    generateGrid(accumulator, theme, key);
    return accumulator;
  }, {}),
});

const JnJGridColumn = (props: Props) => {
  const { classes, className, xs, sm, md, lg, xl } = props;

  const gridClasses= cs(
    {
      [classes[`grid-xs-${String(xs)}`]]: xs !== false,
      [classes[`grid-sm-${String(sm)}`]]: sm !== false,
      [classes[`grid-md-${String(md)}`]]: md !== false,
      [classes[`grid-lg-${String(lg)}`]]: lg !== false,
      [classes[`grid-xl-${String(xl)}`]]: xl !== false,
    }
  )
  return (
    <div
      className={cs(
        classes.gridPadding,
        className,
        gridClasses
      )}
    >
      {props.children}
    </div>
  );
}

export default withStyles(styles)(JnJGridColumn);
