import { call, put } from 'redux-saga/effects';
import { loadFooter } from '../../services/footer';
import * as actions from './action';
import { show503Error } from '../503error/action';
import { ServiceUnavailable } from '../../utils/errors/ServiceUnavailable';

function* fetchFooter() {
  try {
    yield put(actions.fetchFooter.request())
    const res = yield call(loadFooter);
    yield put(actions.fetchFooter.success(res));
  }
  catch(error) {
    if(error instanceof ServiceUnavailable) {
      yield put(show503Error(error.response));
    }
    yield put(actions.fetchFooter.failure('Error Load'));
  }
}

export function* saga() {
  yield fetchFooter();
}
