import * as React from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const styles = () => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  header: {
    flex: '0',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
  },
  footer: {
    flex: '0',
    display: 'flex',
    flexDirection: 'column',
  },
});

interface IRootContainerProps extends WithStyles<typeof styles> {
  header?: React.ReactNode,
  content: React.ReactNode,
  footer?: React.ReactNode,
}

const RootContainer: React.FunctionComponent<IRootContainerProps> = (props) => {
  const {classes, header, footer, content} = props;
  return (
    <div className={classes.root}>
    {header && <div className={classes.header}>{header}</div>}
    <div className={classes.content}>{content}</div>
    {footer && <div className={classes.footer}>{footer}</div>}
    </div>
  );
};

export default withStyles(styles)(RootContainer);
