import { AxiosInstance, AxiosResponse } from "axios";
import { BlockDTO, BlocksResponseDTO, DrupalHtmlDTO, IQueryParams } from "./types";
import { TwoColumnBlock } from "../../type";
import { fetchArticlesCarouselBlock } from "./fetchArticlesCarouselBlock";
import { CardPhotoBlock } from "../../blocks/Card";

type LeftBlock = BlockDTO<'block_content--connect_with', {
  field_body: {
    processed: string
  },
  field_contact_email: string,
  field_contact_name: string,
  field_contact_position: string,
  field_cta_label: string,
  field_heading: string
}>;

function parseConnectWithManagerBlock(leftBlockResponse: AxiosResponse<BlocksResponseDTO<LeftBlock>>,
  rightBlockData: CardPhotoBlock): TwoColumnBlock {

  const rightBlock = rightBlockData;

  const {
    data: [ blockData ]
   } = leftBlockResponse.data;

  return {
    type: 'TwoColumnBlock',
    dataLeft: {
      type: 'connectBlock',
        data: {
          descriptionText: blockData.attributes.field_body.processed,
          managerName: blockData.attributes.field_contact_name,
          managerPosition: blockData.attributes.field_contact_position,
          managerEmail: blockData.attributes.field_contact_email,
          fieldLabel: blockData.attributes.field_cta_label,
          blockHeading: blockData.attributes.field_heading,
        },
    },
    dataRight: rightBlock,
    leftBlockGridConfig:{
      xs: 4,
      xl: 3,
    },
    rightBlockGridConfig:{
      xs: 4,
      xl: 9,
    },
    bgColor: 'grey',
  }
}


export async function fetchConnectWithManagerBlock(axiosInstance: AxiosInstance): Promise<TwoColumnBlock> {

  const leftBlockResponse = await axiosInstance.get<BlocksResponseDTO<LeftBlock>>('/block_content/connect_with');

  const rightBlockData = await fetchArticlesCarouselBlock(axiosInstance,
    {
      'field_component_page': 'home',
    },
    {
      useContainer: false,
    }
  );

  return parseConnectWithManagerBlock(leftBlockResponse, rightBlockData);
}
