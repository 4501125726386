import React from 'react';
import { Field, reduxForm, InjectedFormProps, reset } from 'redux-form';
import { renderTextField, renderReCaptchaField, FormButtons } from '../ReduxFormFields';
import validate from '../common/forgotPasswordValidate';
import { FORGOT_PASSWORD_FORM } from '../../../store/auth/action';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { ForgotPasswordStatus } from '../../../store/auth/reducer';
import ResponseForgotPassword from '../../../pages/responsePages/responseForgotPassword'

const styles = (theme: Theme) => createStyles({
  linkWrapper: {
    textAlign: 'right'
  },
  forgotPassLink: {
    fontSize: 14,
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  errorMessage: {
    padding: '1em 0',
    color: theme.palette.primary.main,
  },
});
interface Props extends WithStyles<typeof styles> {
  onCancel: () => void
  data: any,
}

const ForgotPasswordForm: React.FunctionComponent<Props & InjectedFormProps<{
  email: string;
  recaptcha: string
  onCancel: () => void
}, Props>> = (props) => {
  const {
    handleSubmit,
    pristine,
    submitting,
    onCancel,
    data,
    error,
    classes } = props;

  const errorHandler = (error: string) => {
    switch(error) {
      case 'no_such_account':
        return <div className={classes.errorMessage}>{data.validation.user_not_exist}</div>
      case 'maximum_login_attempts':
        return <div className={classes.errorMessage}>{data.validation.maximum_login_attempts}</div>
      default:
        return <div className={classes.errorMessage}>Something went wrong</div>
    }
  }

  return (
      <form onSubmit={handleSubmit} noValidate>
        {error && errorHandler(error)}
        <Field
          name="email"
          component={renderTextField}
          type="email"
          label={data.placeholder}
          required
        />
        <Field
          name="recaptcha"
          component={renderReCaptchaField}
          required
        />
        <FormButtons
          pristine={pristine}
          submitting={submitting}
          cancelText={data.cancelButtonText}
          submitText={data.buttonText}
          onCancel={onCancel}
        />
      </form>
  )
}

export default withStyles(styles)(reduxForm<{
  email: string,
  recaptcha: string,
  onCancel: ()=>void
}, Props>({
  form: FORGOT_PASSWORD_FORM,
  validate
})(ForgotPasswordForm))
