import * as React from 'react';
import { JnJWelContainer, JnJWelGridRow, JnJWelGridColumn, AngleDownIcon, Typography } from '../../jnj-ui-components';
import { withStyles, createStyles, WithStyles, Theme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { fixText } from '../../utils';
import { IWelcomeBlocks } from '../../type';
import { connect } from 'react-redux';
import {
  initAction,
  destructAction
 } from '../../store/page/action';
import { RootState } from '../../store';
import ContentComponent from '../../components/ContentComponent';

interface Props extends WithStyles<typeof styles> {
  className?: string;
  data: IWelcomeBlocks[] | null;
  init: (id: string) => void;
  isLoading: boolean,
}

const styles = (theme: Theme) => createStyles({
  root: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 300,
    backgroundColor: '#fff',
    flexGrow: 'inherit',
    [theme.breakpoints.up('lg')]: {
      fontSize: 24,
      lineHeight: '36px',
    },
    '& *': {
      margin: 0
    },
    '& h2, h2 span': {
      fontWeight: 900,
      fontSize: 24,
      lineHeight: '28px',
      marginBottom: 25,
      marginTop: 40,
      [theme.breakpoints.up('lg')]: {
        fontSize: 28,
        lineHeight: '36px',
        marginBottom: 10,
        marginTop: 30,
      },
    },
    '& h3, h3 span': {
      fontWeight: 900,
      fontSize: 24,
      lineHeight: '28px',
      marginBottom: 25,
      marginTop: 40,
      [theme.breakpoints.up('lg')]: {
        fontSize: 28,
        lineHeight: '36px',
        marginBottom: 10,
        marginTop: 30,
      },
    },
    '& h4, h4 span': {
      fontSize: 20,
      lineHeight: 26 / 20,
      fontWeight: 900,
      marginTop: 40,
      marginBottom: 25,
      [theme.breakpoints.up('lg')]: {
        marginBottom: 10,
        marginTop: 30,
      },
    },
    '& h5, h5 span': {
      fontSize: 16,
      lineHeight: 22 / 16,
      fontWeight: 900,
      marginBottom: 25,
      marginTop: 40,
      [theme.breakpoints.up('lg')]: {
        marginBottom: 10,
        marginTop: 30,
      },
    },
    '& h6, h6 span': {
      fontSize: 14,
      lineHeight: 20 / 14,
      fontWeight: 900,
      marginBottom: 25,
      marginTop: 40,
      [theme.breakpoints.up('lg')]: {
        marginBottom: 10,
        marginTop: 30,
      },
    },
    '& a': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
    '& img': {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: '100%',
      display: 'block',
    },
    '& p, span, li': {
      marginBottom: 20,
      [theme.breakpoints.up('lg')]: {
        fontSize: 20,
        lineHeight: '36px',
        marginBottom: 10
      }
    },
    '& ul': {
      [theme.breakpoints.up('lg')]: {
        marginBottom: 10
      }
    },
    '& li > p': {
      [theme.breakpoints.up('lg')]: {
        marginBottom: 0
      }
    },
    '& h3.color-primary':{
      color: theme.palette.primary.main,
    },
  },
  articleWrapper: {
    paddingTop: 20,
    paddingBottom: 20,
    [theme.breakpoints.up('lg')]: {
      paddingTop: 90,
      paddingBottom: 90
    },
  },
  homeLink: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  },
  title: {
    fontSize: '32px !important',
    lineHeight: '36px !important',
    marginTop: '0 !important',
    [theme.breakpoints.up('lg')]: {
      fontSize: '44px !important',
      lineHeight: '48px !important',
      marginBottom: 20
    },
  },
  linkArrow: {
    transform: 'rotate(90deg)',
    marginRight: 10,
    width: 24
  },
  linkText: {
    color: '#212121',
    lineHeight: '30px !important',
    marginTop: 2,
    marginBottom: '0px !important',
  },
  cookiesSettingsButton: {
    display: 'flex',
    marginTop: 25,
    marginBottom: 40,
    [theme.breakpoints.up('lg')]: {
      marginBottom: 30,
      marginTop: 10,
    },
  }
});

class CookiePolicy extends React.Component<Props> {

  constructor(props: Props) {
    super(props);
    props.init('cookie-policy');
  }

  render() {
    const {
      classes,
      data,
      isLoading,
    } = this.props;

    const blockData: any = data;

    return (
      <div className={classes.root}>
        <JnJWelContainer className={classes.articleWrapper}>
          {(!isLoading && blockData) &&
            <React.Fragment>
              <JnJWelGridRow>
                <JnJWelGridColumn xs={4} lg={3} xl={2}>
                  <Link to='/' className={classes.homeLink}>
                    <AngleDownIcon className={classes.linkArrow} />
                    <span className={classes.linkText}>
                      {blockData[0].data.promote}
                    </span>
                  </Link>
                </JnJWelGridColumn>
                <JnJWelGridColumn xs={4} lg={7} xl={6}>
                  <Typography variant="h2" color="secondary" className={classes.title}>
                    {fixText(blockData[0].data.title)}
                  </Typography>
                </JnJWelGridColumn>
                <JnJWelGridColumn xs={0} xl={4} />
              </JnJWelGridRow>
              <JnJWelGridRow>
                <JnJWelGridColumn xs={0} lg={3} xl={2} />
                <JnJWelGridColumn xs={4} lg={7} xl={8}>
                  <ContentComponent body={blockData[0].data.body} />
                </JnJWelGridColumn>
              </JnJWelGridRow>
            </React.Fragment>
          }
          <JnJWelGridRow>
            <JnJWelGridColumn xs={0} lg={3} xl={2} />
            <JnJWelGridColumn xs={4} lg={7} xl={8}>
              <div className={classes.cookiesSettingsButton}>
                <a className="optanon-show-settings">Cookie Settings</a>
              </div>
              <div id="optanon-cookie-policy" />
            </JnJWelGridColumn>
          </JnJWelGridRow>
        </JnJWelContainer>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.page['cookie-policy'] ? state.page['cookie-policy'].isLoading : true,
  data: state.page['cookie-policy'] ? state.page['cookie-policy'].data : null
});

const dispatchToProps = {
  init: initAction,
  destruct: destructAction,
};

export default connect(mapStateToProps, dispatchToProps)(withStyles(styles)(CookiePolicy));
