import * as React from 'react';
import AppBar from '@material-ui/core/AppBar';
import cs from 'classnames';
import {
  JnJButton,
  Typography,
  JnJWelContainer,
  LogoIcon,
  SocialIconToolbar,
} from '../../jnj-ui-components';
import { createStyles, WithStyles, withStyles, Theme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { RootState } from '../../store';
import { connect } from 'react-redux';
import { FooterData } from '../../store/footer/type';

const styles = (theme: Theme) => createStyles({
  appBar: {
    backgroundColor: '#fff',
    boxShadow: 'none',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('xl')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'nowrap',
    }
  },
  part: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 0,
    marginRight: 0,
    minHeight: 84,
    justifyContent: 'center',

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      alignItems: 'center',

      '& > *': {
        marginLeft: theme.spacing.unit * 1.5,
        marginRight: theme.spacing.unit * 1.5,
      },
      '& > *:first-child': {
        marginLeft: 0,
      },
      '& > *:last-child': {
        marginRight: 0,
      },
    },
  },
  leftPart: {
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-start',
    },
  },
  rightPart: {
    [theme.breakpoints.up('xl')]: {
      justifyContent: 'flex-end',
    }
  },
  divider: {
    height: 48,
    width: 2,
    backgroundColor: 'rgba(33,33,33,0.1)',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    }
  },
  logo: {
    order: 3,
    width: 214,
    height: 40,
    margin: '30px 0 15px',
    [theme.breakpoints.up('lg')]: {
      order: 0,
      paddingRight: theme.spacing.unit * 1.5,
      marginTop: 0,
      marginBottom: 0
    },
  },
  buttonFixer: {
    marginTop: 30,
    [theme.breakpoints.down('md')]: {
      marginLeft: -theme.spacing.unit * 1.5,
      marginRight: -theme.spacing.unit * 1.5,
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: 0
    }
  },
  footerLink: {
    fontWeight: 300
  },
  policyLinks: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center'
    }
  }
});

interface FooterProps extends WithStyles<typeof styles> {
  footerData?: FooterData
}

const Footer: React.SFC<FooterProps> = ({ classes, footerData }) => {

  return (
    <AppBar position="relative" className={classes.appBar} component="footer">
      <JnJWelContainer className={classes.root}>
        <div className={cs(classes.part, classes.leftPart)}>
          <LogoIcon className={classes.logo} />
          {footerData && <React.Fragment>
            <div className={classes.divider} />
            {Object.keys(footerData).length !== 0 &&
              <SocialIconToolbar
                className={classes.buttonFixer}
                facebookLink={footerData.facebookLink}
                twitterLink={footerData.twitterLink}
                youtubeLink={footerData.youtubeLink}
                linkedinLink={footerData.linkedinLink}
                instagramLink={footerData.instagramLink}
              />
            }
            <div className={classes.divider} />
            <div className={cs(classes.buttonFixer, classes.policyLinks)}>
              {Object.keys(footerData).length !== 0 && footerData.links.map(item => (
                item.linkHref.indexOf('http') === -1 ?
                  (item.linkHref !== '/corporate/cookie-policy' ?
                  <JnJButton
                    key={item.linkHref}
                    component={Link as any}
                    {...{ to: item.linkHref }}
                    className={classes.footerLink}
                  >{item.linkTitle}</JnJButton> :
                  <JnJButton
                    key={item.linkHref}
                    component="a"
                    href={item.linkHref}
                    className={classes.footerLink}
                  >{item.linkTitle}</JnJButton>)
                  :
                  <JnJButton
                    key={item.linkHref}
                    target="_blank"
                    component="a"
                    href={item.linkHref}
                    className={classes.footerLink}
                  >{item.linkTitle}</JnJButton>
              ))}
            </div>
            </React.Fragment>}
          </div>
          <div className={cs(classes.part, classes.rightPart)}>
            <Typography variant="body2" color="textSecondary">
              {footerData && Object.keys(footerData).length !== 0 &&
                footerData.copyright}
            </Typography>
          </div>
      </JnJWelContainer>
    </AppBar>
  );
};

const mapStateToProps = (state: RootState) => ({
  footerData: state.footer.footerData,
});


export default connect(mapStateToProps)(withStyles(styles)(Footer));
