import React from 'react'
import LoginForm from '../../components/ReduxForms/LoginForm';
import { connect } from 'react-redux';
import { login, LoginInput } from '../../store/auth/action';
import { withRouter, RouteComponentProps } from 'react-router';
import { RootState } from '../../store';
import FormWrapper from '../../components/ReduxForms/FormWrapper';

export interface LoginProps extends RouteComponentProps<{}> {
  login: (data: LoginInput) => void
  loginFormData: any,
  isLoading: boolean,
}

class LoginFormPage extends React.Component<LoginProps> {
  cancel = () => {
    this.props.history.goBack();
  }


  render() {
    return !this.props.isLoading &&
      <FormWrapper
        title={this.props.loginFormData.headerText}
      >
        <LoginForm
          data={this.props.loginFormData}
          onSubmit={this.props.login}
          onCancel={this.cancel} />
      </FormWrapper>
  }
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.auth.loading,
  loginFormData: state.auth.loginForm,
});

const dispatchToProps = {
  login: login.request,
};

export default withRouter(connect(mapStateToProps, dispatchToProps)(LoginFormPage));
