import "@babel/polyfill";
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './intl';
import createStore from './store';
import ScrollToTop from './components/ScrollRestoration/ScrollToTop';
import { BrowserSupport } from "./pages/BrowserSupportPage";
import { JnJThemeProvider } from "./jnj-ui-components";
import { setUpFakeToken } from "./utils/fakeAuth";

let isIE = /*@cc_on!@*/false || !!document['documentMode'];

setUpFakeToken();
const store = createStore();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop>
        <JnJThemeProvider>
          {!isIE ? <App /> : <BrowserSupport />}
        </JnJThemeProvider>
      </ScrollToTop>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root') as HTMLElement
);
