import withDrupalBlockLoad from '../../utils/withDrupalBlockLoad';
import fetchNotFoundBlock, { ErrorPageData } from '../../services/fetchBlock/fetchNotFoundBlock';
import ErrorPage from './ErrorPage';
import { LocationDescriptor } from 'history';


export default withDrupalBlockLoad<ErrorPageData, {
  redirectLink?: LocationDescriptor;
  data: ErrorPageData;
}>({
  fetch: () => fetchNotFoundBlock('403'),
})(ErrorPage);
