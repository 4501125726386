import { Reducer } from 'redux';
import * as actions from './action';
import { getType, ActionType } from 'typesafe-actions';

export interface CookiesData {
  information: string;
  acceptButtonText: string;
  cookiePolicyLink: string;
  cookiePolicyText: string;
}

type State = {
  readonly cookiesData: CookiesData | null;
  readonly loading: boolean;
  readonly error?: string;
}

type Actions = ActionType<typeof actions>;

const initialState: State = {
  loading: false,
  cookiesData: null,
};

export const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case getType(actions.fetchCookies.request):
      return {
        ...state,
        loading: true,
      };
    case getType(actions.fetchCookies.success):
      return {
        ...state,
        cookiesData: action.payload,
        error: undefined,
        loading: false,
      };
    case getType(actions.fetchCookies.failure):
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
