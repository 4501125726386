import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import cs from 'classnames';
import {IconProps} from '../type';
import { WithStyles, createStyles } from '@material-ui/core/styles';

const styles = () => createStyles({
  root: {
    width: 20,
    height: 20
  }
});

export interface Props extends IconProps, WithStyles<typeof styles>{

}

const InstagramIcon = (props: Props) => {
  const {classes, className} = props;
  return(
    <SvgIcon className={cs(classes.root, className)} viewBox="0 0 20 20">
      <g fill="#212121" fillRule="evenodd">
        <path d="M10.126.815c-2.528 0-2.845.011-3.839.056-.99.046-1.667.203-2.26.433a4.564 4.564 0 0 0-1.649 1.074 4.564 4.564 0 0 0-1.074 1.65c-.23.592-.387 1.268-.433 2.26-.045.993-.056 1.31-.056 3.838 0 2.529.011 2.846.056 3.84.046.99.203 1.667.433 2.26a4.564 4.564 0 0 0 1.074 1.649 4.564 4.564 0 0 0 1.65 1.074c.592.23 1.268.387 2.26.432.993.046 1.31.056 3.838.056 2.529 0 2.846-.01 3.84-.056.99-.045 1.667-.202 2.26-.432a4.564 4.564 0 0 0 1.649-1.074 4.564 4.564 0 0 0 1.074-1.65c.23-.592.387-1.269.432-2.26.046-.993.056-1.31.056-3.839 0-2.528-.01-2.845-.056-3.839-.045-.99-.202-1.667-.432-2.26a4.564 4.564 0 0 0-1.074-1.649 4.564 4.564 0 0 0-1.65-1.074c-.592-.23-1.269-.387-2.26-.433-.993-.045-1.31-.056-3.839-.056zm0 1.678c2.486 0 2.781.01 3.763.054.908.042 1.4.193 1.729.32.434.17.744.372 1.07.697.326.326.528.636.697 1.071.127.328.279.821.32 1.729.045.982.055 1.276.055 3.762 0 2.486-.01 2.781-.055 3.763-.041.908-.193 1.4-.32 1.729-.17.434-.37.744-.697 1.07a2.884 2.884 0 0 1-1.07.697c-.328.127-.821.279-1.73.32-.98.045-1.275.055-3.762.055-2.486 0-2.78-.01-3.762-.055-.908-.041-1.401-.193-1.729-.32a2.884 2.884 0 0 1-1.07-.697 2.885 2.885 0 0 1-.697-1.07c-.128-.328-.28-.821-.32-1.73-.046-.981-.055-1.276-.055-3.762 0-2.486.01-2.78.054-3.762.042-.908.193-1.401.32-1.729.17-.435.372-.745.697-1.07a2.884 2.884 0 0 1 1.071-.697c.328-.128.821-.28 1.729-.32.982-.046 1.276-.055 3.762-.055z"/><path d="M10.108 13.219a3.111 3.111 0 1 1 0-6.222 3.111 3.111 0 0 1 0 6.222zm0-7.904a4.793 4.793 0 1 0 0 9.586 4.793 4.793 0 0 0 0-9.586zM16.159 5.093a1.11 1.11 0 1 1-2.22 0 1.11 1.11 0 0 1 2.22 0"/>
      </g>
    </SvgIcon>
  )
}

export default withStyles(styles)(InstagramIcon);
