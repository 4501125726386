import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AuthenticatedRoute from '../components/AuthenticatedRoute';
import Login from './Login';
import UnauthenticatedRoute from '../components/UnauthenticatedRoute';
import { NotFoundPage } from './ErrorPages';
import Page from './';
import { RegisterPage } from './RegisterPage';
import { LoginFormPage } from './LoginFormPage';
import { ForgotPassword } from './ForgotPassword';
import { SetPasswordPage } from './SetPassword';
import registrationResponseSuccess from './responsePages/registrationResponseSuccess';
import responseForgotPassword from './responsePages/responseForgotPassword';
import resetPasswordSuccess from './responsePages/resetPasswordSuccess';
import registrationResponseUserExist from './responsePages/registrationResponseUserExist';
import registrationResponseUserNotExist from './responsePages/registrationResponseUserNotExist';
import registrationResponseUserVerExpired from './responsePages/registrationResponseUserVerExpired';
import registrationResponseUserVerSuccess from './responsePages/registrationResponseUserVerSuccess';
import RegistrationVerification from './RegistrationVerification';
import AuthSwitchRoute from '../components/AuthSwitchRoute';
import CookiePolicy from './CookiePolicyPage/CookiePolicy';

interface RootRouterProps {

}

const isDevelopment = process.env.NODE_ENV === 'development';

const RootRouter: React.FunctionComponent<RootRouterProps> = () => (
  <Switch>
    <AuthSwitchRoute exact path="/"
      component={() => <Page id="home" bgColor="grey" />}
      authComponent={Login}
    />
    <AuthenticatedRoute path="/just-for-you" component={() => <Page id="just-for-you" />} />
    <AuthenticatedRoute exact path="/your-community" component={() => <Page id="your-community" />} />
    <AuthenticatedRoute exact path="/your-company" component={() => <Page id="your-company" />} />
    <AuthenticatedRoute
      exact path="/article/:articleId"
      render={(props) => <Page key={props.match.params.articleId} id={props.match.params.articleId} />} />
    <Route
      exact path="/corporate/cookie-policy"
      component={CookiePolicy} />
    <Route
      exact path="/corporate/:policyPage"
      render={(props) => <Page key={props.match.params.policyPage} id={props.match.params.policyPage} />} />
    <UnauthenticatedRoute exact path="/register" component={RegisterPage} />
    <UnauthenticatedRoute exact path="/login/form" component={LoginFormPage} />
    <UnauthenticatedRoute exact path="/login/forgot-password" component={ForgotPassword} />
    <UnauthenticatedRoute exact path="/forgot-password/success" component={resetPasswordSuccess} />
    <UnauthenticatedRoute exact path="/set-new-password" component={SetPasswordPage} />
    <UnauthenticatedRoute exact path="/register/success" component={registrationResponseSuccess} />
    {isDevelopment &&
      <UnauthenticatedRoute exact path="/register/user-exist" component={registrationResponseUserExist} />}
    {isDevelopment &&
      <UnauthenticatedRoute exact path="/register/user-not-exist" component={registrationResponseUserNotExist} />}
    <UnauthenticatedRoute exact path="/register/verification" component={RegistrationVerification}/>
    {isDevelopment &&
    <UnauthenticatedRoute exact path="/register/verification-expired" component={registrationResponseUserVerExpired} />
    }
    {isDevelopment &&
    <UnauthenticatedRoute exact path="/register/verification-success" component={registrationResponseUserVerSuccess} />
    }
    <Redirect from="/login" to="/" />
    <Route path="*" component={NotFoundPage} />
  </Switch>
);

export default RootRouter;
