import * as React from 'react';
import qs from 'qs';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { checkSignUpCode } from '../../services/auth';
import RegistrationResponseUserVerExpired from '../responsePages/registrationResponseUserVerExpired';
import RegistrationResponseUserVerSuccess from '../responsePages/registrationResponseUserVerSuccess';
import { ErrorBlock } from '../../blocks/ErrorBlock';

export interface RegistrationVerificationProps extends RouteComponentProps {}

interface RegistrationVerificationState {
  isLoading: boolean;
  isValid: boolean | null;
  error?: any;
}

export class RegistrationVerification extends React.Component<
  RegistrationVerificationProps,
  RegistrationVerificationState
> {
  constructor(props: RegistrationVerificationProps) {
    super(props);
    this.state = {
      isLoading: true,
      isValid: null,
    };
  }

  async componentDidMount() {
    try {
      const { verification_code = '' } = qs.parse(
        this.props.location.search.replace('?', '')
      );
      this.setState({
        isLoading: false,
        isValid: await checkSignUpCode(verification_code),
      });
    } catch (error) {
      this.setState({
        isLoading: false,
        error,
      });
    }
  }

  public render() {
    const { isLoading, isValid, error } = this.state;
    if (isLoading) {
      return (<div>Loading...</div>);
    } else if (error) {
      return (<ErrorBlock error={error}/>);
    } else {
      return isValid ? (
        <RegistrationResponseUserVerSuccess />
      ) : (
        <RegistrationResponseUserVerExpired />
      );
    }
  }
}

export default withRouter(RegistrationVerification);
