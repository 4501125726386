import { AxiosInstance, AxiosResponse } from "axios";
import { BlockDTO, BlocksResponseDTO, DrupalHtmlDTO } from "./types";
import { ITabNavBlock } from "../../type";

type TabNavBlockDTO = BlockDTO<'node--component_page_tabs', {

}>;

type TabFieldBlockDTO = BlockDTO<'paragraph--menu_link', {
  field_link_title: string;
  field_link_href: string;
}>;

function parseTabNavBlock(response: AxiosResponse<BlocksResponseDTO<TabNavBlockDTO, TabFieldBlockDTO>>): ITabNavBlock {
  const {
    data: [ blockData ],
    included: paragraphsData,
   } = response.data;

  return {
      type: "TabNavBlock",
      data: paragraphsData.map(({attributes}) => ({
        title: attributes.field_link_title,
        link: attributes.field_link_href,
        id: attributes.field_link_href === '/' ? 'health-and-wellness' :
        attributes.field_link_href.replace('/','')
      })),
  };
}

export async function fetchTabNavBlock(axiosInstance: AxiosInstance, pageId: string): Promise<ITabNavBlock> {
  const blockResponse = await axiosInstance.
  get<BlocksResponseDTO<TabNavBlockDTO, TabFieldBlockDTO>>('/node/component_page_tabs', {
    params: {
      filter: {
        'field_component_page': pageId,
      },
      include: [
        'field_component_tabs',
      ].join()
    },
  });

  return parseTabNavBlock(blockResponse);
}
