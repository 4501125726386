import { IWelcomeBlocks, ErrorBlock } from '../type';

import axiosInstance from './api';
import fetchArticleBlock from './fetchBlock/fetchArticleBlock';
import { fetchGetReadyBlock } from './fetchBlock/fetchGetReadyBlock';
import { fetchBottomBlock } from './fetchBlock/fetchBottomBlock';
import { fetchHeadingBlock } from './fetchBlock/fetchHeadingBlock';
import { fetchGlobalCommitmentBlock } from './fetchBlock/fetchGlobalCommitment';
import { fetchFirstDayChecklistBlock } from './fetchBlock/fetchFirstDayChecklistBlock';
import { fetchInstagramBlock } from './fetchBlock/fetchInstagramBlock';
import { fetchAdvantagesBlock } from './fetchBlock/fetchAdvantagesBlock';
import { fetchValueBlock } from './fetchBlock/fetchValueBlock';
import { fetchInnovationHistoryBlock } from './fetchBlock/fetchInnovationHistory';
import { fetchTabNavBlock } from './fetchBlock/fetchTabNavBlock';
import { fetchArticlesCarouselBlock } from './fetchBlock/fetchArticlesCarouselBlock';
import { fetchVideosCarouselBlock } from './fetchBlock/fetchCardVideoBlock';
import { fetchVideoBlockWithHeading } from './fetchBlock/fetchVideoBlockWithHeading';
import { fetchTwoColumnBlockCareerPath } from './fetchBlock/fetchTwoColumnBlockCareerPath';
import { fetchWelcomeHeadingBlock } from './fetchBlock/fetchWelcomeHeadingBlock';
import { fetchLeadershipBlock } from './fetchBlock/fetchLeadershipBlock';
import { fetchConnectWithManagerBlock } from './fetchBlock/fetchConnectWithManagerBlock';
import { Error404 } from '../utils/errors/Error404';
import { fetchCarouselWithHeading } from './fetchBlock/fetchCarouselWIthHeading';
import { Error403 } from '../utils/errors/Error403';

const errorBlock: ErrorBlock = {
  type: 'ErrorBlock',
  message: 'Something went wrong',
}

export async function combineLoadBlocks(loaders: Promise<IWelcomeBlocks>[]): Promise<IWelcomeBlocks[]> {
  try {
    return await Promise.all(loaders.map(
      p => p.catch((e) => {
        if (e instanceof Error403) {
          throw e
        }
        return { ...errorBlock, data: e };
      })
    ));

  }
  catch(error) {
    return [{
      type: 'Error403Block',
      data: error as Error403,
    }]
  }
}

async function fetchHomeData(): Promise<IWelcomeBlocks[]> {
  return await combineLoadBlocks([
    fetchWelcomeHeadingBlock(axiosInstance),
    fetchGetReadyBlock(axiosInstance, 'home'),
    fetchConnectWithManagerBlock(axiosInstance),
    fetchBottomBlock(axiosInstance, 'home'),
  ]);
}

async function fetchJustForYouData(): Promise<IWelcomeBlocks[]> {
  return await combineLoadBlocks([
    fetchHeadingBlock(axiosInstance, {
      filter: {
        'field_component_page': 'just-for-you',
      },
    }, { bgColor: 'grey', pageHeading: true }),
    fetchTabNavBlock(axiosInstance, 'just-for-you'),
    fetchBottomBlock(axiosInstance, 'just-for-you'),
  ]);
}

async function fetchHealthAndWellnessData(): Promise<IWelcomeBlocks[]> {
  return await combineLoadBlocks([
    fetchGlobalCommitmentBlock(axiosInstance, 'health-and-wellness'),
    fetchVideosCarouselBlock(axiosInstance, {
      'field_component_page': 'health-and-wellness',
    }),
    fetchFirstDayChecklistBlock(axiosInstance, 'health-and-wellness'),
  ]);
}

async function fetchFamilyData(): Promise<IWelcomeBlocks[]> {
  return await combineLoadBlocks([
    fetchAdvantagesBlock(axiosInstance, 'family'),
    fetchArticlesCarouselBlock(axiosInstance,
      {
        'field_component_page': 'family',
      },
      { useContainer: true, color: 'grey' }),
    fetchInstagramBlock(axiosInstance, 'family'),
  ]);
}

async function fetchYourCommunityData(): Promise<IWelcomeBlocks[]> {
  return await combineLoadBlocks([
    fetchHeadingBlock(axiosInstance, {
      filter: {
        'field_component_page': 'your-community',
      },
    }, { bgColor: 'grey', pageHeading: true }),
    fetchVideoBlockWithHeading(axiosInstance,
      {
        'field_component_page': 'your-community',
        'field_weight': 0,
      }
    ),
    fetchLeadershipBlock(axiosInstance, {
      filter: {
        'field_component_page': 'your-community',
        'field_weight': 1
      },
      include: [
        'field_component_simple_paragraph'
      ].join(),
    }),
    fetchCarouselWithHeading(axiosInstance, {
      'field_component_page': 'your-community',
      'field_weight': 1,
    },
    { useContainer: true, color: 'grey' }),
    fetchBottomBlock(axiosInstance, 'your-community'),
  ]);
}

async function fetchYourCompanyData(): Promise<IWelcomeBlocks[]> {
  return await combineLoadBlocks([
    fetchHeadingBlock(axiosInstance, {
      filter: {
        'field_component_page': 'your-company',
      },
    }, { bgColor: 'grey', pageHeading: true }),
    fetchValueBlock(axiosInstance, 'your-company'),
    fetchVideosCarouselBlock(axiosInstance, {
      'field_component_page': 'your-company',
      'field_weight': 0,
    }),
    fetchInnovationHistoryBlock(axiosInstance, 'your-company'),
    fetchArticlesCarouselBlock(axiosInstance,
      {
        'field_component_page': 'your-company',
      },
      { useContainer: true, color: 'grey' }
    ),
    fetchVideosCarouselBlock(axiosInstance, {
      'field_component_page': 'your-company',
      'field_weight': 1,
    }),
    fetchBottomBlock(axiosInstance, 'your-company'),
  ]);
}

async function fetchCareerPathData(): Promise<IWelcomeBlocks[]> {
  return await combineLoadBlocks([
    fetchHeadingBlock(axiosInstance,
      {
        filter: {
          'field_component_page': 'career-path',
          'field_weight': 0,
        },
      }
    ),
    fetchTwoColumnBlockCareerPath(axiosInstance, 'career-path', {leftBlockWeight: 1, rightBlockWeight: 2}),
    fetchVideosCarouselBlock(axiosInstance,
      {
        'field_component_page': 'career-path',
        'field_weight': 0,
      }
    ),
    fetchHeadingBlock(axiosInstance,
      {
        filter: {
          'field_component_page': 'career-path',
          'field_weight': 3,
        },
      }
    ),
    fetchVideosCarouselBlock(axiosInstance,
      {
        'field_component_page': 'career-path',
        'field_weight': 1,
      },
    ),
  ]);
}

export async function fetch(pageId: string): Promise<IWelcomeBlocks[]> {
  switch (pageId) {
    case 'home':
      return await fetchHomeData();
    case 'just-for-you':
      return await fetchJustForYouData();
    case 'health-and-wellness':
      return await fetchHealthAndWellnessData();
    case 'family':
      return await fetchFamilyData();
    case 'career-path':
      return await fetchCareerPathData();
    case 'your-community':
      return await fetchYourCommunityData();
    case 'your-company':
      return await fetchYourCompanyData();
    default:
      try {
        return await fetchArticleBlock(axiosInstance, pageId);
      }
      catch(e) {
        if (e instanceof Error403) {
          return [{
            type: 'Error403Block',
            data: e as Error403,
          }];
        } else if (e instanceof Error404) {
          return [{
            type: 'Error404Block',
            data: e as Error404,
          }];
        } else {
          return [{
            ...errorBlock,
            data: e,
          }];
        }
      }
  }
}
