import { BlocksResponseDTO, BlockDTO, ImageBlockDTO } from './types';
import { AxiosInstance, AxiosResponse } from 'axios';
import { CardVideoBlock } from '../../type';
import { IVideo } from '../../components/commonTypes';
import { requestUrl } from '../api';

export type VideosCarouselBlockDTO = BlockDTO<'node--component_videos_slider', {
  field_component_picture_align: 'left' | 'right';
  field_header_rich: {
    value: string;
  },
}>;

type VideoParagraphDTO = BlockDTO<'paragraph--brightcove_video', {
  field_cta_label: string;
  field_component_description_rich: {
    processed?: string;
  };
  field_component_header_sub?: string;
  field_component_header: {
    processed?: string;
  };
},
  {
    field_component_videos: {
      data: {
        id: string;
      };
    }
  }>


type VideoBlockDTO = BlockDTO<'brightcove_video--brightcove_video', {
  video_id: string;
  duration: string;
}, {
  api_client: {
    data: {
      target_uuid: {
        account_id: string;
        player_id: string;
      };
    };
  };
  poster: {
    data: {
      id: string;
    };
  };
}>

export interface VideoBlockFilterParams {
  'field_component_page': string,
  'field_weight'?: number;
}

type ICarouselInnerParams = Partial<Pick<CardVideoBlock, 'useContainer'>>

export async function fetchVideosCarouselBlock(axiosInstance: AxiosInstance,
  filterParams: VideoBlockFilterParams, innerParams?: ICarouselInnerParams): Promise<CardVideoBlock> {
  const blockResponse = await fetchVideoBlockData(axiosInstance, filterParams);

  return parseVideoBlockData(blockResponse, innerParams);

}
export function parseVideoBlockData(blockResponse: AxiosResponse<BlocksResponseDTO<VideosCarouselBlockDTO, VideoBlockDTO | ImageBlockDTO | VideoParagraphDTO>>,
  innerParams?: ICarouselInnerParams): CardVideoBlock {

  const { data: [blockData], included: videosData } = blockResponse.data;

  type AgrType = {
    imageMap: Record<string, ImageBlockDTO>;
    videoBlocksMap: Record<string, VideoBlockDTO>;
    paragraphBlocks: VideoParagraphDTO[];
  };
  const { imageMap = {}, videoBlocksMap = {}, paragraphBlocks = [] }: AgrType = videosData.reduce((agr: AgrType, record) => {
    switch (record.type) {
      case 'file--file':
        return {
          ...agr,
          imageMap: {
            ...agr.imageMap,
            [record.id]: record,
          },
        };
      case 'brightcove_video--brightcove_video':
        return {
          ...agr,
          videoBlocksMap: {
            ...agr.videoBlocksMap,
            [record.id]: record,
          },
        };
      case 'paragraph--brightcove_video':
        return {
          ...agr,
          paragraphBlocks: [
            ...agr.paragraphBlocks,
            record,
          ],
        }
    }
  }, {
      imageMap: {},
      videoBlocksMap: {},
      paragraphBlocks: [],
    });

  return {
    type: 'CardVideoBlock',
    data: {
      rtl: blockData.attributes.field_component_picture_align === 'left',
      stories: paragraphBlocks.map((paragraph) => {
        const videoBlockId = paragraph.relationships.field_component_videos.data.id;
        return ({
          headerInfo: paragraph.attributes.field_component_header !== null ? paragraph.attributes.field_component_header.processed : undefined,
          description: paragraph.attributes.field_component_description_rich !== null ?
            paragraph.attributes.field_component_description_rich.processed : undefined,
          video: {
            type: 'Brightcove',
            providerId: parseInt(videoBlocksMap[videoBlockId].relationships.api_client.data.target_uuid.account_id, 10),
            playerId: videoBlocksMap[videoBlockId].relationships.api_client.data.target_uuid.player_id,
            videoId: parseInt(videoBlocksMap[videoBlockId].attributes.video_id, 10),
          } as IVideo,
          imgSrc: requestUrl + imageMap[videoBlocksMap[videoBlockId].relationships.poster.data.id].attributes.uri.url,
          subHeader: paragraph.attributes.field_component_header_sub !== null ? paragraph.attributes.field_component_header_sub : undefined,
          videoDuration: videoBlocksMap[videoBlockId].attributes.duration,
          ctaText: paragraph.attributes.field_cta_label,
        })
      }),
    },
    useContainer: true,
    ...innerParams,
  };
}

export async function fetchVideoBlockData(axiosInstance: AxiosInstance, filterParams: VideoBlockFilterParams)
  : Promise<AxiosResponse<BlocksResponseDTO<VideosCarouselBlockDTO, VideoBlockDTO | ImageBlockDTO>>> {
  return await axiosInstance.get<BlocksResponseDTO<VideosCarouselBlockDTO, VideoBlockDTO | ImageBlockDTO>>('/node/component_videos_slider', {
    params: {
      filter: filterParams,
      include: [
        'field_component_videos_slides',
        'field_component_videos_slides.field_component_videos',
        'field_component_videos_slides.field_component_videos.poster',
      ].join(','),
    },
  });
}

