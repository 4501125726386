import * as React from 'react';
import { Subtract } from 'utility-types';

interface WithDrupalBlockLoadState<D extends object> {
  isLoading: boolean;
  data: D | null;
  error?: Error;
}

const withDrupalBlockLoad = <
  D extends object,
  P extends { data: D }
>(settings: {
  fetch: () => Promise<D>;
}) => (Component: React.ComponentType<P>) =>
  class WithDrupalBlockLoad extends React.Component<
    Subtract<P, { data: D }>,
    WithDrupalBlockLoadState<D>,
    any
  > {
    state: WithDrupalBlockLoadState<D> = {
      isLoading: true,
      data: null,
    };

    async componentDidMount() {
      try {
        const data = await settings.fetch();
        this.setState({ data });
      } catch (error) {
        this.setState({
          error,
        });
      } finally {
        this.setState({
          isLoading: false,
        });
      }
    }

    render() {
      const { isLoading, data } = this.state;
      return isLoading ? (
        <div>Loading...</div>
      ) : this.state.data !== null ? (
        <Component {...this.props as P} data={data} />
      ) : (
        <div>Server issues</div>
      );
    }
  };

export default withDrupalBlockLoad;
