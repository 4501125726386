import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import cs from 'classnames';
import {IconProps} from '../type';
import { WithStyles, createStyles } from '@material-ui/core/styles';

const styles = () => createStyles({
  root: {
    width: 16,
    height: 14
  }
});

export interface Props extends IconProps, WithStyles<typeof styles>{

}

const TwitterIcon = (props: Props) => {
  const {classes, className} = props;
  return(
    <SvgIcon className={cs(classes.root, className)} viewBox="0 0 16 14">
      <path d="M15.16.604a6.09 6.09 0 0 1-1.975.78 3.054 3.054 0 0 0-2.27-1.016C9.2.368 7.807 1.81 7.807 3.588c0 .252.026.497.08.732C5.302 4.186 3.01 2.906 1.477.956a3.302 3.302 0 0 0-.422 1.62 3.25 3.25 0 0 0 1.384 2.68 3.031 3.031 0 0 1-1.41-.402v.04c0 1.56 1.073 2.86 2.496 3.156-.26.075-.535.113-.82.113-.2 0-.395-.02-.585-.057.396 1.278 1.544 2.21 2.905 2.234a6.105 6.105 0 0 1-4.604 1.335 8.601 8.601 0 0 0 4.767 1.445c5.72 0 8.848-4.905 8.848-9.159 0-.14-.002-.28-.008-.417a6.416 6.416 0 0 0 1.55-1.666 6.03 6.03 0 0 1-1.785.507c.643-.398 1.136-.32 1.368-1.072" fill="#212121" fillRule="evenodd"/>
    </SvgIcon>
  )
}

export default withStyles(styles)(TwitterIcon);
