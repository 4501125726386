import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles';
import { WithStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Typography } from '../../../jnj-ui-components';
import cs from 'classnames'
import HtmlSinitizer from '../../HtmlSanitizer/HtmlSinitizer';

const styles = (theme: Theme) => createStyles({
  root: {
    backgroundColor: '#fff',
    padding: '20px 10px 0px',
    width: '100%',
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.up('lg')]: {
      flexGrow: 'inherit',
      paddingBottom: 20,
    },
    '& form': {
      width: '100%',
      maxWidth: 500,
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: 0,
    }
  },
  positionStyles: {
    maxWidth: 500,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  title: {
    marginBottom: 40,
  },
});

interface Props extends WithStyles<typeof styles> {
  children: React.ReactNode,
  title: string,
  description?: string,
}

const FormWrapper = (props: Props) => {
  const {
    children,
    classes,
    title,
    description,
  } = props

  const formDescription = description ? description.split('\n') : undefined;

  return (
    <div className={classes.root}>
      <Typography variant="h2" className={cs(classes.title, classes.positionStyles)}>
        {title}
      </Typography>
      {formDescription &&
        <div className={classes.positionStyles}>
          {formDescription.map((paragraph, index) =>
            <HtmlSinitizer key={index} content={paragraph}/>
          )}
        </div>
      }
      {children}
    </div>
  )
}

export default withStyles(styles)(FormWrapper)
