import withStyles, { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';

const styles: StyleRulesCallback = (theme) => ({
  root: {
    borderRadius: '0',
    textTransform: 'capitalize',
    padding: '13px',
    fontSize: '14px',
  },

  contained: {
    boxShadow: 'none',
  },

  containedPrimary: {
    '&:disabled': {
      color: 'white',
    },
  },

  containedSecondary: {
    '&:disabled': {
      color: 'white',
    },
  },

  sizeSmall: {
    padding: '8px',
    fontSize: '14px',
    fontWeight: 300,
  },

  sizeLarge: {
    padding: '17px',
    fontSize: '16px',
  },

  outlinedPrimary: {
    fontWeight: 500,
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  },

  outlinedSecondary: {
    fontWeight: 500,
    '&:hover': {
      border: `1px solid ${theme.palette.secondary.main}`,
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
    },
  },

});

export default withStyles(styles)(Button);
