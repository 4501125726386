import { createAction, createAsyncAction } from "typesafe-actions";
import { IWelcomeBlocks } from "../../type";

export const initAction = createAction('@@Page/INIT', (resolve) => (pageID: string) => resolve(pageID));
export const destructAction = createAction('@@Page/DESTRUCT', (resolve) => (pageID: string) => resolve(pageID));
type SubPageActionPayload = {pageID: string};
export const fetchAction = createAsyncAction('@@Page/FETCH', '@@Page/FETCH_SUCCESS', '@@Page/FETCH_FAIL')<
  SubPageActionPayload,
  SubPageActionPayload & {data: IWelcomeBlocks[]},
  SubPageActionPayload & {error: string}
>();
