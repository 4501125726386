import { call, put } from 'redux-saga/effects';
import { loadHeader } from '../../services/header';
import * as actions from './action';
import { show503Error } from '../503error/action';
import { ServiceUnavailable } from '../../utils/errors/ServiceUnavailable';

function* fetchHooter() {
  try {
    yield put(actions.fetchHeader.request())
    const res = yield call(loadHeader);
    yield put(actions.fetchHeader.success(res));
  }
  catch(error) {
    if(error instanceof ServiceUnavailable) {
      yield put(show503Error(error.response));
    }
    yield put(actions.fetchHeader.failure(error));
  }
}

export function* saga() {
  yield fetchHooter();
}
