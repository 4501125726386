import * as React from 'react';
import cs from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import { FacebookIcon, TwitterIcon, YouTubeIcon, LinkedInIcon, InstagramIcon } from '../JnJIcons';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

const styles = createStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  icon: {
    width: 50,
    height: 50,
  },
  facebookIcon: {
    width: 14,
    height: 26
  },
  twitterIcon: {
    width: 21,
    height: 18
  },
  youtubeIcon: {
    width: 26,
    height: 19
  },
  linkedinIcon: {
    width: 22,
    height: 18
  },
  instagramIcon: {
    width: 26,
    height: 26
  }
});

interface SocialIconToolbarProps extends WithStyles<typeof styles> {
  className?: string;
  facebookLink: string;
  twitterLink: string;
  youtubeLink: string;
  linkedinLink: string;
  instagramLink: string;
}

const SocialIconToolbar: React.SFC<SocialIconToolbarProps> = ({
  classes,
  className,
  facebookLink,
  twitterLink,
  youtubeLink,
  linkedinLink,
  instagramLink,
}) => {
  return (
    <div className={cs(classes.root, className)}>
      <IconButton component="a" href={facebookLink} target="_blank" className={classes.icon} aria-label="Facebook">
        <FacebookIcon className={classes.facebookIcon} />
      </IconButton>
      <IconButton component="a" href={twitterLink} target="_blank" className={classes.icon} aria-label="Twitter">
        <TwitterIcon className={classes.twitterIcon}/>
      </IconButton>
      <IconButton component="a" href={youtubeLink} target="_blank" className={classes.icon} aria-label="YouTube">
        <YouTubeIcon className={classes.youtubeIcon} />
      </IconButton>
      <IconButton component="a" href={linkedinLink} target="_blank" className={classes.icon} aria-label="LinkedIn">
        <LinkedInIcon className={classes.linkedinIcon} />
      </IconButton>
      <IconButton component="a" href={instagramLink} target="_blank" className={classes.icon} aria-label="Instagram">
        <InstagramIcon className={classes.instagramIcon} />
      </IconButton>
    </div>
  );
};

export default withStyles(styles)(SocialIconToolbar);
