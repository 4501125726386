import React from 'react'
import { connect } from 'react-redux';
import { RootState } from '../../store';
import { ResponseComponent } from '../../components/ResponsePageComponent';
import { ResponseComponentData } from '../../components/ResponsePageComponent/ResponseComponent';

export interface Props {
  responseData: ResponseComponentData;
  email: string;
  isLoading: boolean;
}

class RegistrationSuccess extends React.Component<Props> {
  formatData(): ResponseComponentData {
    const {responseData, email} = this.props;
    return {
      ...responseData,
      description: responseData.description.replace('@mail', email),

    }
  }

  render() {
    return !this.props.isLoading &&
      <ResponseComponent data={this.formatData()} />;
  }
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.auth.loading,
  responseData: state.auth.registrationResponseSuccess,
});

export default connect(mapStateToProps)(RegistrationSuccess);
