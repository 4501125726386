import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import cs from 'classnames';
import {IconProps} from '../type';
import { WithStyles, createStyles } from '@material-ui/core/styles';

const styles = () => createStyles({
  root: {
    width: 20,
    height: 16
  }
});

export interface Props extends IconProps, WithStyles<typeof styles>{

}

const YouTubeIcon = (props: Props) => {
  const {classes, className} = props;
  return(
    <SvgIcon className={cs(classes.root, className)} viewBox="0 0 20 16">
      <path d="M8.044 9.64l5.118-2.696L8.043 4.23V9.64zM18.53 1.38c.568.584.753 1.91.753 1.91s.19 1.56.19 3.118V7.87c0 1.559-.19 3.118-.19 3.118s-.185 1.327-.753 1.911c-.72.768-1.528.771-1.898.816-2.652.196-6.633.201-6.633.201s-4.926-.045-6.442-.193c-.422-.08-1.368-.056-2.09-.824-.567-.584-.752-1.911-.752-1.911s-.19-1.56-.19-3.118V6.408c0-1.558.19-3.117.19-3.117s.185-1.327.752-1.912C2.19.612 2.997.61 3.368.563 6.017.368 9.995.368 9.995.368h.008s3.977 0 6.629.195c.37.045 1.177.049 1.898.816z" fill="#212121" fillRule="evenodd"/>
    </SvgIcon>
  )
}

export default withStyles(styles)(YouTubeIcon);
