import * as React from "react";
import { Route, RouteProps, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { RootState } from "../store";

interface AuthRoute extends RouteProps {
  isAuthenticated: boolean;
  authComponent?:  React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
  authRender?: ((props: RouteComponentProps<any>) => React.ReactNode);
}

const AuthSwitchRoute: React.SFC<AuthRoute> =  ({
  component: C,
  authComponent: AC,
  render = () => null,
  authRender = () => null,
  isAuthenticated,
  ...rest
}) =>
  <Route
    {...rest}
    render={props =>
      isAuthenticated
        ? C ? React.createElement(C, props) : render(props)
        : AC ? React.createElement(AC, props) : authRender(props)
      }
  />;

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: state.auth.isLogged,
});

export default connect(mapStateToProps)(AuthSwitchRoute);
