import { AxiosInstance } from "axios";
import { BlocksResponseDTO, BlockDTO, DrupalHtmlDTO } from "./types";
import axiosInstance from "../api";

export type NotFoundBlockDTO = BlockDTO<'block_content--error_block', {
  body: DrupalHtmlDTO;
  field_cta_label: string;
  field_error_type: string;
  field_heading: string;
}>

type NotFoundResponseDTO = BlocksResponseDTO<NotFoundBlockDTO>
export type ErrorCodes = '403' | '404';
export type ErrorPageData = {
  title: string;
  description: string;
  ctaLabel?: string;
}

export function parseNotFoundBlock(data: NotFoundBlockDTO): ErrorPageData {
  return {
    title: data.attributes.field_heading,
    description: data.attributes.body.value,
    ctaLabel: data.attributes.field_cta_label,
  }
}

export async function requestNotFoundBlock(aInstance: AxiosInstance, code: ErrorCodes): Promise<NotFoundBlockDTO> {
  const serverResponse = await aInstance.get<NotFoundResponseDTO>('/block_content/error_block', {
    params: {
      filter: {
        'field_error_type': code,
      },
    },
  });

  return serverResponse.data.data[0];
}



export default async function fetchNotFoundBlock(code: ErrorCodes): Promise<ErrorPageData> {
  const res = await requestNotFoundBlock(axiosInstance, code);

  return parseNotFoundBlock(res);
}
