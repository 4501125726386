import qs from "qs";
import { restoreAuthData, storeAuthData } from "../store/auth/reducer";

export function setUpFakeToken() {
  if (location.search) {
    const { fakeAuth } = qs.parse(location.search.replace('?', ''));


    const currentAuth = restoreAuthData()
    if (!!fakeAuth && (currentAuth === null || currentAuth.accessToken !== fakeAuth)) {
      storeAuthData({
        accessToken: fakeAuth,
        expiresIn: Date.now() + 5 * 60 * 1000,
        refreshToken: 'none',
        userData: {
          name: 'FakeName',
          lastName: 'FakeLastName',
          managerEmail: 'FakeManagerEmail',
          managerFamilyName: 'FakeManagerFamilyName',
          managerPosition: 'FakeManagerPosition',
          isJJUser: false,
          position: '',
          date: null,
          email: '',
          managerName: '',
        },
      });
    }
  }
}



