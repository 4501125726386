import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import {IconProps} from './type';
import cs from 'classnames';
import { WithStyles, createStyles } from '@material-ui/core/styles';

const styles = () => createStyles({
  icon: {
    width: '30px',
    height: '30px'
  }
});

export interface Props extends IconProps, WithStyles<typeof styles>{

}

const TwoPersonsIcon = (props: Props) => {
  const {classes, className} = props;
  return(
    <SvgIcon className={cs(classes.icon, className)} viewBox="0 0 67 71">
      <g fill="none" fillRule="evenodd">
        <path d="M46.771 70.65a6.598 6.598 0 0 1-4.435-1.707A6.598 6.598 0 0 1 37.9 70.65c-3.648 0-6.616-2.962-6.616-6.603 0-1.202.977-2.176 2.181-2.176 1.205 0 2.181.974 2.181 2.176a2.255 2.255 0 0 0 4.509 0V44.128c0-1.202.976-2.176 2.18-2.176 1.205 0 2.182.974 2.182 2.176v19.92a2.255 2.255 0 0 0 4.508 0V34.39a2.183 2.183 0 0 1 4.132-.973c.37.739 1.51 2.49 4.98 5.916a2.611 2.611 0 0 0 3.67-.009c.49-.49.761-1.142.761-1.835 0-.694-.27-1.346-.761-1.836l-9.545-9.525a6.653 6.653 0 0 0-4.73-1.955H29.03a2.179 2.179 0 0 1-2.181-2.177c0-1.202.976-2.176 2.18-2.176h18.503c2.952 0 5.727 1.147 7.815 3.23l9.544 9.525a6.896 6.896 0 0 1 2.04 4.914 6.896 6.896 0 0 1-2.04 4.913c-2.7 2.696-7.107 2.707-9.822.026a70.176 70.176 0 0 1-1.682-1.715v23.334c0 3.641-2.968 6.603-6.616 6.603" fill="#009"/>
        <path d="M26.775 64.047a2.255 2.255 0 0 0 4.51 0V34.39c0-1.01.695-1.886 1.68-2.118.984-.23 1.998.242 2.45 1.145.371.739 1.51 2.49 4.98 5.916a2.611 2.611 0 0 0 3.67-.009c.491-.49.762-1.142.762-1.835 0-.694-.27-1.346-.762-1.836l-9.544-9.525a6.653 6.653 0 0 0-4.73-1.955H19.398a6.653 6.653 0 0 0-4.73 1.955l-9.544 9.525a2.576 2.576 0 0 0-.762 1.836c0 .693.27 1.345.762 1.835a2.611 2.611 0 0 0 3.67.01c3.47-3.427 4.609-5.178 4.98-5.917a2.183 2.183 0 0 1 4.13.973v29.657a2.255 2.255 0 0 0 4.51 0V44.128c0-1.202.976-2.176 2.18-2.176 1.205 0 2.181.974 2.181 2.176v19.92zm2.255 6.603a6.598 6.598 0 0 1-4.436-1.707 6.598 6.598 0 0 1-4.435 1.707c-3.648 0-6.616-2.962-6.616-6.603V40.713a69.858 69.858 0 0 1-1.682 1.715c-2.715 2.681-7.121 2.67-9.822-.026A6.897 6.897 0 0 1 0 37.49c0-1.856.724-3.602 2.04-4.914l9.543-9.525a10.991 10.991 0 0 1 7.815-3.23h10.393c2.952 0 5.727 1.147 7.814 3.23l9.544 9.525a6.896 6.896 0 0 1 2.04 4.914 6.896 6.896 0 0 1-2.04 4.913c-2.7 2.696-7.106 2.707-9.821.026a69.858 69.858 0 0 1-1.682-1.715v23.334c0 3.641-2.968 6.603-6.616 6.603z" fill="#CA001B"/>
        <path d="M24.594 5.14a3.364 3.364 0 0 0-3.363 3.356 3.364 3.364 0 0 0 6.727 0 3.363 3.363 0 0 0-3.364-3.357m0 11.066c-4.26 0-7.725-3.458-7.725-7.71 0-4.25 3.466-7.709 7.725-7.709 4.26 0 7.726 3.459 7.726 7.71 0 4.25-3.466 7.71-7.726 7.71" fill="#009"/>
        <path d="M42.336 5.14a3.363 3.363 0 0 0-3.363 3.356 3.364 3.364 0 0 0 6.726 0 3.364 3.364 0 0 0-3.363-3.357m0 11.066c-4.26 0-7.725-3.458-7.725-7.71 0-4.25 3.465-7.709 7.725-7.709s7.725 3.459 7.725 7.71c0 4.25-3.465 7.71-7.725 7.71" fill="#C09"/>
      </g>
    </SvgIcon>
  )
}

export default withStyles(styles)(TwoPersonsIcon);
