import React from 'react'
import TextField from '@material-ui/core/TextField';
import {WrappedFieldProps} from 'redux-form';
import cs from 'classnames';
import {WithStyles, createStyles, Theme, withStyles} from '@material-ui/core/styles';

const styles = (theme: Theme) => createStyles({
  field: {
    width: '100%',
    '& > div:before, & > div:after': {
      borderBottom: '2px solid #212121',
    },
    '& label': {
      color: '#212121',
    },
    '& label > span': {
      color: theme.palette.primary.main,
    },
  },
  fieldWrapper: {
    marginBottom: 20,
  },
  errorMessage: {
    color: theme.palette.primary.main,
    fontSize: 12,
  },
  errorInput: {
    '& > div:before, & > div:after': {
      // borderBottom: '2px solid',
      borderColor: theme.palette.primary.main,
    },
    '& label': {
      color: theme.palette.primary.main,
    }
  }
});

// export interface Props {
export interface Props extends WithStyles<typeof styles>, WrappedFieldProps {
  label?: string,
  className?: string,
  required?: boolean,
  validate?: () => void
}

const renderTextField = (props: Props) => {
  const {
    input,
    label,
    className,
    required,
    classes,
    meta: { touched, error },
    ...custom
  } = props

  return <div className={classes.fieldWrapper}>
    <TextField
      label={label}
      placeholder={label}
      required={required}
      className={cs(classes.field,
        {[classes.errorInput]: (error && touched)},
      )}
      {...input}
      {...custom}
    />
    {touched && error && <span className={classes.errorMessage}>{error}</span>}
  </div>

}

// export default renderTextField;
export default withStyles(styles)(renderTextField) as 'input' & React.ComponentType<Props>;
