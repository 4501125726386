import { take, select, call, put, all, fork } from 'redux-saga/effects';
import { getType, ActionType } from 'typesafe-actions';
import { startSubmit, stopSubmit, setSubmitSucceeded } from 'redux-form';
import * as actions from './action';
import { RootState } from '..';
import { loadLogin } from '../../services/login';
import { login, signUp, forgotPassword, setNewPassword } from '../../services/auth';
import { show503Error } from '../503error/action';
import { ServiceUnavailable } from '../../utils/errors/ServiceUnavailable';

function* fetchLogin() {
  try {
    yield put(actions.fetchLogin.request())
    const res = yield call(loadLogin);
    yield put(actions.fetchLogin.success(res));
  }
  catch(error) {
    if(error instanceof ServiceUnavailable) {
      yield put(show503Error(error.response));
    }
    yield put(actions.fetchLogin.failure('Error Load'));
  }
}

function* loginSaga(inputData: actions.LoginInput) {
  try {
    yield put(startSubmit(actions.LOGIN_FORM));

    const loginResponse = yield call(login, inputData);
    yield put(actions.login.success(loginResponse));
    yield put(setSubmitSucceeded(actions.LOGIN_FORM));
    yield put(stopSubmit(actions.LOGIN_FORM));
  }
  catch(error) {
    yield put(actions.login.failure('faiiiill'));
    yield put(stopSubmit(actions.LOGIN_FORM, {_error: error.error}))
  }
}

function* loginSagaWatch() {
  while(true) {
    const isLogged = yield select((state: RootState) => state.auth.isLogged)
    if (!isLogged) {
      const inputData = (yield take(getType(actions.login.request))) as ActionType<typeof actions.login.request>;

      yield call(loginSaga, inputData.payload);
    } else {
      yield take(getType(actions.logout));
    }
  }
}

function* signUpSaga(inputData: actions.SignUpInput) {;
  try {
    yield put(startSubmit(actions.SIGN_UP_FORM));
    const response = yield call(signUp, inputData);
    yield put(actions.signUp.success(response));
    yield put(setSubmitSucceeded(actions.SIGN_UP_FORM));
    yield put(stopSubmit(actions.SIGN_UP_FORM));
  }
  catch(error) {
    yield put(actions.signUp.failure(error.error));
    yield put(stopSubmit(actions.SIGN_UP_FORM, {_error: error.error}));
  }
}

function* signUpSagaWatch() {
  while(true) {
    const isLogged = yield select((state: RootState) => state.auth.isLogged)
    if (!isLogged) {
      const inputData = (yield take(getType(actions.signUp.request))) as ActionType<typeof actions.signUp.request>;

      yield call(signUpSaga, inputData.payload);
    } else {
      yield take(getType(actions.logout));
    }
  }
}

function* forgotPasswordSaga(inputData: actions.forgotPasswordInput) {
  try {
    yield put(startSubmit(actions.FORGOT_PASSWORD_FORM));
    const response = yield call(forgotPassword, inputData);
    yield put(actions.forgotPassword.success(response));
    yield put(setSubmitSucceeded(actions.FORGOT_PASSWORD_FORM));
    yield put(stopSubmit(actions.FORGOT_PASSWORD_FORM));
  }
  catch(error) {
    yield put(actions.forgotPassword.failure(error.error));
    yield put(stopSubmit(actions.FORGOT_PASSWORD_FORM, {_error: error.error}))
    throw error;
  }
}

function* forgotPasswordSagaWatch() {
  while(true) {
    try {
      const inputData = (yield take(getType(actions.forgotPassword.request))) as
      ActionType<typeof actions.forgotPassword.request>;
      yield call(forgotPasswordSaga, inputData.payload);
    }
    catch(error) {
      console.error(error);
    }
  }
}

function* setNewPasswordSaga(inputData: actions.newPasswordInput) {
  try {
    yield put(startSubmit(actions.NEW_PASSWORD_FORM));
    const response = yield call(setNewPassword, inputData);
    yield put(actions.setNewPassword.success(response));
    yield put(setSubmitSucceeded(actions.NEW_PASSWORD_FORM));
    yield put(stopSubmit(actions.NEW_PASSWORD_FORM));
  }
  catch(error) {
    yield put(actions.setNewPassword.failure(error.error));
    yield put(stopSubmit(actions.NEW_PASSWORD_FORM, {_error: error.error}))
    throw error;
  }
}

function* setNewPasswordSagaWatch() {
  while(true) {
    try {
      const inputData = (yield take(getType(actions.setNewPassword.request))) as
      ActionType<typeof actions.setNewPassword.request>;
      yield call(setNewPasswordSaga, inputData.payload);
    }
    catch(error) {
      console.error(error);
    }
  }
}

export function* saga() {
  yield put(actions.restoreAuth());
  yield fetchLogin();
  yield all([
    yield fork(signUpSagaWatch),
    yield fork(loginSagaWatch),
    yield fork(forgotPasswordSagaWatch),
    yield fork(setNewPasswordSagaWatch),
  ]);
}
