import { call, put } from 'redux-saga/effects';
import { loadCookies } from '../../services/cookies';
import * as actions from './action';
import { show503Error } from '../503error/action';
import { ServiceUnavailable } from '../../utils/errors/ServiceUnavailable';

function* fetchCookies() {
  try {
    yield put(actions.fetchCookies.request())
    const res = yield call(loadCookies);
    yield put(actions.fetchCookies.success(res));
  }
  catch(error) {
    if(error instanceof ServiceUnavailable) {
      yield put(show503Error(error.response));
    }
    yield put(actions.fetchCookies.failure('Something went wrong. Please reload the page or try again later'));
  }
}

export function* saga() {
  yield fetchCookies();
}
