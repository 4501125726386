import { emailRegExp } from "./regExp";

const validate = (values: {email: string, recaptcha: string}, props: any) => {

  type errors = {
    email?: string,
    recaptcha?: string
  }

  const errors: errors = {}
  if(!values.recaptcha) {
    errors.recaptcha = props.data.validation.captcha
  }
  if(!emailRegExp.test(values.email)) {
    errors.email = props.data.validation.email_not_valid
  }
  return errors
}

export default validate
