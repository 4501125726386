import * as React from 'react';
import cs from 'classnames';
import Collapse from '@material-ui/core/Collapse';
import withStyles from '@material-ui/core/styles/withStyles';
import { WithStyles, createStyles, Theme } from '@material-ui/core/styles';
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../../store/auth/action';
import { RootState } from '../../store';
import { DrawerIcon, JnJWelContainer } from '../../jnj-ui-components';
import { HeaderData } from '../../store/header/type';
import { FooterData } from '../../store/footer/type';
import { LanguageSelector } from '../LanguageSelector';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  isLogged: boolean;
  logout: () => void;
  className?: string;
  headerData?: HeaderData
  footerData?: FooterData
}

const BRAKE_SIZE = 'md';
const HEADER_HEIGHT = 100;

interface JnJHeaderState {
  isOpen: boolean;
}

const styles = (theme: Theme) => createStyles({
  root: {
    position: 'fixed',
    width: '100%',
    top: 0,
    zIndex: theme.zIndex.appBar,
    fontFamily: theme.typography.fontFamily,
    backgroundColor: '#fff',
    [theme.breakpoints.down(BRAKE_SIZE)]: {
      zIndex: theme.zIndex.drawer,
    }
  },
  headerSpace: {
    height: HEADER_HEIGHT,
    [theme.breakpoints.down(BRAKE_SIZE)]: {
      height: 76
    }
  },
  rootHeader: {
    height: HEADER_HEIGHT,
    [theme.breakpoints.down(BRAKE_SIZE)]: {
      height: 76,
    },
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  whiteBg: {
    background: 'white',
  },
  rootMenu: {
    width: '100vw',
    height: 'auto',
    display: 'flex',
    [theme.breakpoints.down(BRAKE_SIZE)]: {
      height: `100vh`,
      borderTop: `1px solid ${theme.palette.divider}`,
    }
  },
  logoBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginRight: 'auto',
  },
  logo: {
    textDecoration: 'none',
    marginTop: 2
  },
  tabs: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& $tab': {
      marginLeft: 23,
      marginRight: 23
    },
    [theme.breakpoints.down(BRAKE_SIZE)]: {
      display: 'none',
    }
  },
  tab: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    height: HEADER_HEIGHT,
    textDecoration: 'none',
    fontWeight: 900,
    lineHeight: '25px',
    color: '#212121',
    fontSize: 18,
    position: 'relative',
    [theme.breakpoints.down(BRAKE_SIZE)]: {
      height: 55,
      fontSize: 20,
      lineHeight: 1,
      display: 'block',
      paddingTop: (55-20)/2,
      paddingBottom: (55-20)/2,
    }
  },
  activeTab: {
    '&:after': {
      position: 'absolute',
      width: '100%',
      content: '""',
      left: 0,
      height: 3,
      backgroundColor: '#CA001B',
      bottom: 25,
    },
    [theme.breakpoints.down(BRAKE_SIZE)]: {
      '&:after': {
        display: 'none',
      },
    },
  },
  drawer: {
    display: 'none',
    order: 2,
    [theme.breakpoints.down(BRAKE_SIZE)]: {
      display: 'block',
      height: '32px',
    }
  },
  divider: {
    marginLeft: 10,
    marginRight: 10,
    width: 2,
    height: 48,
    background: theme.palette.divider,
    [theme.breakpoints.down(BRAKE_SIZE)]: {
      width: '100%',
      height: 1,
      margin: '20px 0'
    }
  },
  mobileNav: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
    width: '100%'
  },
  languageSelectorWrapper: {
    marginRight: 20,
    [theme.breakpoints.up('lg')]: {
      marginLeft: 30,
      marginTop: 3,
      marginRight: 15,
    },
  }
});

class Header extends React.Component<Props, JnJHeaderState> {

  state = {
    isOpen: false,
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }

  logOut = () => {
    this.setState({ isOpen: false });
    this.props.logout();
    this.props.history.push('/');
  }

  navClick = () => {
    this.setState({isOpen: false})
  }

  render() {
    const {
      className,
      isLogged,
      classes,
      headerData,
      footerData,
    } = this.props;

    const { isOpen } = this.state;

    const links = headerData ? [
      headerData!.links.length > 0 && headerData!.links.map(item => (
        <NavLink
          key={item.id}
          to={item.href}
          onClick={this.navClick}
          className={classes.tab}
          activeClassName={classes.activeTab}
        >
          {item.title}
        </NavLink>
      )),
      <div key="divider" className={classes.divider} />,
      <nav key="logout" className={classes.tab} onClick={this.logOut}>{headerData!.logOtButtonText}</nav>,
    ] : [];

    return (
      <React.Fragment>
        <div className={classes.root}>
          <JnJWelContainer className={cs(className, classes.rootHeader)}>
            <div className={classes.logoBlock}>
              <NavLink to="/" className={classes.logo} onClick={this.navClick}>
                <img src={require('../../assets/Welcome.svg')} alt="Site logo" />
              </NavLink>
            </div>
            {!isLogged || <React.Fragment>
              <div className={classes.tabs}>
                {links}
              </div>
              <DrawerIcon className={classes.drawer} isOpen={isOpen} onClick={this.toggle} />
            </React.Fragment>}
            {footerData && footerData.languages &&
            Object.keys(footerData.languages).length > 1 &&
              <div className={classes.languageSelectorWrapper}>
                <LanguageSelector data={footerData.languages} />
              </div>
            }
          </JnJWelContainer>

          <Collapse classes={{ wrapperInner: classes.rootMenu }} in={isOpen}>
            <JnJWelContainer static className={classes.whiteBg}>
              <div className={classes.mobileNav}>
                {links}
              </div>
            </JnJWelContainer>
          </Collapse>
        </div>
        <div className={classes.headerSpace} />
      </React.Fragment>
    );
  }
};

const mapStateToProps = (state: RootState) => ({
  isLogged: state.auth.isLogged,
  headerData: state.header.headerData,
  footerData: state.footer.footerData,
});

const dispatchToProps = {
  logout
};

export default withRouter(connect(mapStateToProps, dispatchToProps)(withStyles(styles)(Header)));
