import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IconProps } from './type';
import { WithStyles, createStyles } from '@material-ui/core/styles';
import cs from 'classnames';

const styles = () => createStyles({
  root: {
    width: 45,
    height: 47
  }
});

export interface Props extends IconProps, WithStyles<typeof styles> {
  color?: string;
}

const PlayIcon = (props: Props) => {
  const { classes, className, color="#fff"} = props;
  return (
    <SvgIcon className={cs(classes.root, className)} viewBox="0 0 45 47">
        <path d="M4.98 2.12L5.13 2.15L5.29 2.2L5.44 2.26L5.59 2.34L40.69 21.84L40.84 21.93L40.98 22.03L41.1 22.14L41.21 22.25L41.32 22.38L41.4 22.51L41.48 22.65L41.55 22.79L41.6 22.93L41.64 23.08L41.67 23.23L41.69 23.39L41.7 23.54L41.69 23.7L41.67 23.85L41.64 24L41.6 24.15L41.55 24.29L41.48 24.44L41.4 24.57L41.32 24.7L41.21 24.83L41.1 24.95L40.98 25.05L40.84 25.16L40.69 25.25L5.59 44.75L5.37 44.73L5.16 44.71L4.96 44.68L4.77 44.65L4.59 44.62L4.41 44.58L4.24 44.54L4.08 44.49L3.93 44.44L3.79 44.39L3.66 44.33L3.54 44.27L3.42 44.21L3.31 44.14L3.21 44.07L3.12 44L3.04 43.92L2.97 43.84L2.91 43.75L2.85 43.66L2.8 43.57L2.76 43.47L2.73 43.37L2.71 43.26L2.7 43.15L2.7 43.04L2.7 4.04L2.7 3.87L2.72 3.71L2.76 3.55L2.8 3.4L2.86 3.25L2.93 3.11L3.01 2.98L3.09 2.86L3.19 2.74L3.3 2.63L3.41 2.53L3.53 2.44L3.66 2.36L3.79 2.29L3.93 2.23L4.07 2.18L4.21 2.14L4.36 2.11L4.51 2.09L4.67 2.09L4.82 2.1L4.98 2.12ZM35.73 23.54L6.6 7.36L6.6 39.73L35.73 23.54Z" fill={color} fillOpacity="1"></path>
    </SvgIcon >
  )
}

export default withStyles(styles)(PlayIcon);
