import { UserData } from "../services/auth";

export const NON_BRAKING_SPACE = '\u00A0';
export function fixJnJ(basic: string) {
  return basic.replace(
    /Johnson (&|&amp;) Johnson/g,
    'Johnson &' + NON_BRAKING_SPACE + 'Johnson'
  );
}

export function detectMob() {
  if( navigator.userAgent.match(/Android/i)
  || navigator.userAgent.match(/webOS/i)
  || navigator.userAgent.match(/iPhone/i)
  || navigator.userAgent.match(/iPad/i)
  || navigator.userAgent.match(/iPod/i)
  || navigator.userAgent.match(/BlackBerry/i)
  || navigator.userAgent.match(/Windows Phone/i)
  ){
     return true;
   }
  else {
     return false;
   }
 }

export function fixText(text?: string | null): string {
  return text !== null && text ? fixJnJ(text) : '';
}

export function transformToLocalDate(date: Date): Date{
  const userStartDate = new Date(date.valueOf());
  return new Date(userStartDate.getUTCFullYear(), userStartDate.getUTCMonth(), userStartDate.getUTCDate());
}
