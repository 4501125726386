import React from 'react'
import { Field, reduxForm, InjectedFormProps} from 'redux-form'
import {withStyles, Theme, createStyles, WithStyles} from '@material-ui/core/styles';
import { renderTextField, renderReCaptchaField, FormButtons } from '../ReduxFormFields';
import validate from '../common/registerValidate';
import { SIGN_UP_FORM } from '../../../store/auth/action';

const styles = (theme: Theme) => createStyles({
  errorMessage: {
    padding: '1em 0',
    color: theme.palette.primary.main,
  },
});
interface Props extends WithStyles<typeof styles> {
  onCancel: () => void;
  data: any,
}

const RegisterForm: React.FunctionComponent<Props & InjectedFormProps<{
  email: string;
  password: string;
  recaptcha: string
  checkPassword: string;
}, Props>> = (props) => {
  const {
    handleSubmit,
    pristine,
    submitting,
    onCancel,
    data,
    error,
    classes,
  } = props;

  const errorHandler = (error: string) => {
    switch(error) {
      case 'workday_request_failed':
        return <div className={classes.errorMessage}>
            {data.validation.workday_not_responding || 'Workday not responding'}
          </div>
      default:
        return <div className={classes.errorMessage}>Something went wrong</div>
    }
  }
  return (
    <form onSubmit={handleSubmit} noValidate>
      {error && errorHandler(error)}
      <Field
        name="email"
        component={renderTextField}
        type="email"
        label={data.placeholders.email}
        required
      />
      <Field
        name="password"
        component={renderTextField}
        type="password"
        label={data.placeholders.password}
        required
      />
      <Field
        name="checkPassword"
        component={renderTextField}
        type="password"
        label={data.placeholders.password_repeat}
        required
      />
      <Field
        name="recaptcha"
        component={renderReCaptchaField}
        required
      />
      <FormButtons
        pristine={pristine}
        submitting={submitting}
        cancelText={data.cancelButtonText}
        submitText={data.buttonText}
        submitProcessedText={data.processing}
        onCancel={onCancel}
      />
    </form>
  )
}

export default withStyles(styles)(reduxForm<{
  email: string;
  password: string;
  recaptcha: string;
  checkPassword: string;
}, Props>({
  form: SIGN_UP_FORM,
  validate,
})(RegisterForm))
