import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { createStyles, WithStyles, withStyles, Theme } from '@material-ui/core/styles';
import {
  Typography,
  JnJWelContainer,
  JnJWelGridRow,
  JnJWelGridColumn,
} from '../../jnj-ui-components';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    backgroundColor: '#fff',
  },
  rootHeader: {
    flex: '0 1 1',
    height: 100,
    [theme.breakpoints.down('md')]: {
      height: 76,
    },
  },
  logoBlock: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  container: {
    height: 'calc(100vh - 100px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  heading: {
    fontSize: 40,
    lineHeight: '44px',
    marginBottom: 10,
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      fontSize: 80,
      lineHeight: 1.05,
    },
    [theme.breakpoints.up('xl')]: {
      lineHeight: 1.25,
    },
  },
  description: {
    lineHeight: 1.56,
    [theme.breakpoints.up('md')]: {
      marginBottom: 58,
      fontSize: 28,
      lineHeight: 1.32,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 40,
      lineHeight: 1.2,
    },
  },
});
interface BrowserSupportProps extends WithStyles<typeof styles> {
}

const BrowserSupport: React.SFC<BrowserSupportProps> = (props) => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <JnJWelContainer className={classes.rootHeader}>
          <div className={classes.logoBlock}>
              <img src={require('../../assets/Welcome.svg')} alt="Site logo" />
          </div>
      </JnJWelContainer>
      <JnJWelContainer>
        <JnJWelGridRow>
          <JnJWelGridColumn xs={0} md={1} xl={2} />
          <JnJWelGridColumn xs={4} md={6} xl={6} className={classes.container}>
            <Typography variant="h1" color="primary" className={classes.heading}>
              <FormattedMessage
                id="browser-support.title"
                defaultMessage="Oops!"
              />
            </Typography>
            <Typography variant="body1" className={classes.description}>
              <FormattedMessage
                id="browser-support.description"
                defaultMessage="The browser you are currently using is not supported for this site.
                Please visit Welcome.jnj.com on Chrome or Safari for the best experience. "
              />
            </Typography>
          </JnJWelGridColumn>
        </JnJWelGridRow>
      </JnJWelContainer>
    </div>
  );
};

export default withStyles(styles)(BrowserSupport);
