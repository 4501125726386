import React from 'react'
import ForgotPasswordForm from '../../components/ReduxForms/ForgotPassword';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { RootState } from '../../store';
import FormWrapper from '../../components/ReduxForms/FormWrapper';
import { forgotPassword, forgotPasswordInput, cleanUp } from '../../store/auth/action';
import { ForgotPasswordStatus } from '../../store/auth/reducer';
import ResponseForgotPassword from '../responsePages/responseForgotPassword';

interface Props extends RouteComponentProps<{}> {
  isLoading: boolean,
  forgotPasswordData: any,
  requestStatus: ForgotPasswordStatus,
  forgotPasswordSubmit: (data: forgotPasswordInput) => void,
  cleanUp: () => void,
}

class ForgotPasswordPage extends React.Component<Props> {

  state = {userEmail: 'your email'}

  componentWillUnmount() {
    this.props.cleanUp();
  }

  handleSubmit(data: forgotPasswordInput) {
    this.props.forgotPasswordSubmit(data);
    this.setState({
      userEmail: data.email,
    })
  }

  cancel = () => {
    this.props.history.goBack();
  }
  render() {
    return !this.props.isLoading && (
      this.props.requestStatus !== 'ok' ?
        <FormWrapper
          title={this.props.forgotPasswordData.headerText}
          description={this.props.forgotPasswordData.description}
        >
          <ForgotPasswordForm
            onSubmit={(values) => this.handleSubmit(values)}
            onCancel={this.cancel}
            data={this.props.forgotPasswordData} />
        </FormWrapper> :
        <ResponseForgotPassword email={this.state.userEmail} />
    )
  }
}

const dispatchToProps = {
  forgotPasswordSubmit: forgotPassword.request,
  cleanUp: cleanUp,
};

const mapStateToProps = (state: RootState) => ({
  isLoading: state.auth.loading,
  forgotPasswordData: state.auth.forgotPasswordForm,
  requestStatus: state.auth.forgotPasswordStatus,
});

export default withRouter(connect(mapStateToProps, dispatchToProps)(ForgotPasswordPage));
