import * as React from 'react';

interface Props {
  error?: any;
  message?: string;
  data?: any;
}

const ErrorBlock: React.FunctionComponent<Props> = (props) => {
  if (process.env.NODE_ENV === 'development') {
    return <div>
      {props.error}
      {props.message}
      <pre>
        {JSON.stringify(props.data, null, '')}
      </pre>
    </div>;
  } else {
    return <div/>;
  }
}

export default ErrorBlock;
