import React from 'react'
import {WithStyles, createStyles, Theme, withStyles} from '@material-ui/core/styles';
import { JnJButton } from '../../../jnj-ui-components';

const styles = (theme: Theme) => createStyles({
  formButtons: {
    display: 'flex',
    marginTop: 25,
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 70,
    },
  },
  cancelButton: {
    marginTop: 15,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: 0,
    }
  },
  cancelLink: {
    color: '#212121',
    textDecoration: 'none',
  },
  proceedButton: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: 150,
      marginRight: 10,
    }
  },
});

export interface Props extends WithStyles<typeof styles> {
  submitText: string,
  submitProcessedText?: string,
  cancelText: string,
  pristine: boolean,
  submitting: boolean,
  onCancel: ()=>void
}

const FormButtons = (props: Props) => {
  const {
    classes,
    submitText,
    submitProcessedText,
    cancelText,
    pristine,
    submitting,
    onCancel,
  } = props

  return (
    <div>
      <div className={classes.formButtons}>
        <JnJButton
          type="submit"
          disabled={pristine || submitting}
          fullWidth
          className={classes.proceedButton}
          color="primary"
          variant="contained"
        >
          {submitText}
        </JnJButton>
        <JnJButton
          onClick={onCancel}
          className={classes.cancelButton}
          variant="contained"
        >
          {cancelText}
        </JnJButton>
      </div>
      <p>{((submitting) && submitProcessedText)}</p>
    </div>
  )
}

export default withStyles(styles)(FormButtons);
