import { passwordRegExp } from "./regExp";

const validate = (values: {password: string, checkPassword: string}, props: any) => {

  type errors = {
    password?: string
    checkPassword?: string
  }

  const errors: errors = {}

  if(!passwordRegExp.test(values.password)) {
    errors.password = props.data.validation.password_not_strong
  }
  if(!passwordRegExp.test(values.checkPassword)) {
    errors.checkPassword = props.data.validation.password_not_strong
  }
  if (values.password !== values.checkPassword) {
    errors.checkPassword = props.data.validation.password_not_match
  }
  return errors
}

export default validate
