import { AxiosInstance, AxiosResponse } from "axios";
import { BlockDTO, BlocksResponseDTO, ImageBlockDTO } from "./types";
import { ComponentWithHeading } from "../../type";
import { requestUrl } from "../api";

type CoreValueBlockDTO = BlockDTO<'node--component_value_block', {
  field_header: string;
  field_component_sub_title: string;
  field_component_items_text: Array<string>;
}>;


function parseValueBlock(response: AxiosResponse<BlocksResponseDTO<CoreValueBlockDTO,
  ImageBlockDTO>>): ComponentWithHeading {
  const {
    data: [ blockData ],
    included: [ paragraphsData ],
   } = response.data;

  return {
    type: 'ComponentWithHeading',
    data: {
      title: blockData.attributes.field_header,
    },
    component: {
      type: 'Core Values',
      data: {
        title: blockData.attributes.field_component_sub_title,
        imgSrc: requestUrl + paragraphsData.attributes.uri.url,
        list: blockData.attributes.field_component_items_text.map(item => (
          item
        ))
      }
    }
  };
}

export async function fetchValueBlock(axiosInstance: AxiosInstance, pageId: string): Promise<ComponentWithHeading> {
  const blockResponse = await axiosInstance.get
  <BlocksResponseDTO<CoreValueBlockDTO, ImageBlockDTO>>('/node/component_value_block', {
    params: {
      filter: {
        'field_component_page': pageId,
      },
      include: [
        'field_component_image',
      ].join()
    },
  });

  return parseValueBlock(blockResponse);
}
