import { emailRegExp, passwordRegExp } from "./regExp";

const validate = (values: {email: string, password: string, checkPassword: string,
  recaptcha: string}, props: any) => {

  type errors = {
    email?: string,
    password?: string
    checkPassword?: string
    recaptcha?: string
  }

  const errors: errors = {}

  if(!values.recaptcha) {
    errors.recaptcha = props.data.validation.captcha
  }
  if(!emailRegExp.test(values.email)) {
    errors.email = props.data.validation.email_not_valid
  }
  if(!passwordRegExp.test(values.password)) {
    errors.password = props.data.validation.password_not_strong
  }
  if(!passwordRegExp.test(values.checkPassword)) {
    errors.checkPassword = props.data.validation.password_not_strong
  }
  if (values.password !== values.checkPassword) {
    errors.checkPassword = props.data.validation.password_not_match
  }
  return errors
}

export default validate
