import { AxiosInstance, AxiosResponse } from 'axios';
import { BlockDTO, BlocksResponseDTO, ImageBlockDTO, DrupalHtmlDTO } from './types';
import { IArticleBlock } from '../../type';
import { requestUrl } from '../api';
import { Error404 } from '../../utils/errors/Error404';

type ArticleBlockDTO = BlockDTO<'node--article', {
  body: DrupalHtmlDTO;
  field_article_author: string;
  field_article_date: Date;
  field_article_description:{
    processed: string
  };
  field_article_person_name: string;
  field_article_person_position: string;
  field_header: string;
  field_article_path: string;
  promote: string;
  field_article_display_icon: boolean;
  field_article_reference_cta: string;
  field_article_reference_label: string;
}>;

type ArticleResponseDTO = BlocksResponseDTO<ArticleBlockDTO, ImageBlockDTO | ArticleBlockDTO>;

function parseArticleBlock(response:
  AxiosResponse<ArticleResponseDTO>): IArticleBlock {
  const {
    data: [blockData],
    included: includedData,
  } = response.data;

  let imgUrl:string = '';

  let nextArticleData = {
    title:'',
    link:'',
    linkText:'',
    label:''
  }

  if(includedData) {
    includedData.forEach(item => {
      if(item.type === 'file--file'){
        imgUrl = item.attributes.uri.url
      }
      else {
        nextArticleData.title = item.attributes.field_header
        nextArticleData.link = item.attributes.field_article_path
      }
    })
  }

  return {
    type: 'ArticleBlock',
    data: {
      imgSrc: requestUrl + imgUrl,
      description: blockData.attributes.field_article_description !== null ?
      blockData.attributes.field_article_description.processed : undefined,
      title: blockData.attributes.field_header,
      body: blockData.attributes.body.processed,
      author: blockData.attributes.field_article_author,
      date: blockData.attributes.field_article_date,
      promote: blockData.attributes.promote,
      hasMuse: blockData.attributes.field_article_display_icon === true,
      linkText: blockData.attributes.field_article_reference_cta,
      label: blockData.attributes.field_article_reference_label,
      nextArticle: nextArticleData,
    },
  };
}

export default async function fetchArticleBlock(axiosInstance: AxiosInstance,
  pageId: string): Promise<IArticleBlock[]> {
  const blockResponse = await axiosInstance.
    get<ArticleResponseDTO>
    (`/node/article/`, {
      params: {
        filter: {
          'field_article_path': pageId,
        },
        include: [
          'field_image',
          'field_article_next',
        ].join(),
      },
    });

  if (!blockResponse.data.data.length) {
    throw new Error404();
  }

  return [parseArticleBlock(blockResponse)]
}
