import { LoginInput, SignUpInput, forgotPasswordInput, newPasswordInput } from "../store/auth/action";
import axiosInstance from "./api";

export const LOGIN_PATH = '../user/login/janrain';
export const SIGN_UP_PATH = '../user/register/janrain';
export const FORGOT_PASSWORD_PATH = '../user/forgot_password/janrain';
export const TOKEN_RESET_PASSWORD = '../user/verify_code/janrain';
export const SET_NEW_PASSWORD_PATH = '../user/update_password/janrain';

export type UserData = {
  name: string;
  lastName: string;
  email: string;
  position: string;
  date: Date | null;
  managerName: string;
  managerFamilyName: string;
  managerPosition: string;
  managerEmail: string;
  isJJUser: boolean;
};

export type LoginResponse = {
  accessToken: string;
  expiresIn: number;
  refreshToken: string;
  userData: UserData;
};

export type SignUpResponse = boolean;

export type forgotPasswordResponse = {
  status: string
}

export type newPasswordResponse = {
  status: string
}

export type resetPasswordTokenRespons = {
  accessToken: string,
}

type UserDto = {
  email: string;
  givenName: string;
  familyName: string;
  employeePosition: string;
  employeeStartDate: string;
  managerFamilyName: string;
  managerGivenName: string;
  managerPosition: string;
  managerEmail: string;
}

type SuccessServerResponse = {
  has_errors: false;
};

type SuccessLoginServerResponse = {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  user_data: UserDto;
};

type SuccessSignUpServerResponse = {
  has_errors: false;
  access_token: string;
  capture_user: UserDto;
};

type FailureServerResponse = {
  has_errors: true;
  code: number;
  error: 'invalid_credentials' | 'workday_user_not_exist' | 'workday_request_failed';
  invalid_fields: {
    signInForm: string[];
    emailAddress?: string[];
  };
};

type SuccessForgotPasswordServerResponse = {
  has_errors: false;
  stat: string;
};

type FailureForgotPasswordServerResponse = {
  has_errors: true;
  stat: string;
  code: number;
  error_description: string;
  error: 'no_such_account';
  request_id: string;
  invalid_fields: {
    forgotPasswordForm: string[];
  }
};

type SuccessResetPasswordTokenResponse = {
  has_errors: false,
  access_token: string,
};

type FailureResetPasswordTokenResponse = {
  has_errors: true;
  stat: string;
  code: number;
  error_description: string;
  error: 'no_such_account';
  request_id: string;
};

type SuccessNewPasswordServerResponse = {
  has_errors: false;
  stat: string;
};

type FailureNewPasswordServerResponse = {
  has_errors: true;
  stat: string;
  code: number;
  error_description: string;
  error: 'invalid_access_token';
  request_id: string;
};

function parseUserDataResponse(data: UserDto): UserData {
  return {
    name: data.givenName,
    lastName: data.familyName,
    email: data.email,
    position: data.employeePosition,
    date: data.employeeStartDate ? new Date(data.employeeStartDate) : null,
    managerFamilyName: data.managerFamilyName,
    managerName: data.managerGivenName,
    managerPosition: data.managerPosition,
    managerEmail: data.managerEmail,
    isJJUser: false,
  }
}

async function postToJanrain<T>(path: string, data: object): Promise<T> {
  const { data: response } = await axiosInstance.post<SuccessServerResponse & T | FailureServerResponse>(
    `../user/${path}/janrain`,
    data,
    {
      params: {
        '__format': 'json',
      },
      method: 'json',
    }
  );

  if (!response.has_errors) {
    return response;
  } else {
    throw response;
  }
}

export async function login(data: LoginInput): Promise<LoginResponse> {
  try {
    const response = await postToJanrain<SuccessLoginServerResponse>('login', {
      signInEmailAddress: data.email,
      currentPassword: data.password,
      recaptcha_response: data.recaptcha,
    });
    return {
      accessToken: response.access_token,
      expiresIn: (Date.now() + response.expires_in * 1000),
      refreshToken: response.refresh_token,
      userData: parseUserDataResponse(response.user_data),
    };
  } catch(error) {
    if (error.code === 390) {
      throw {
        error: 'maximum_login_attempts',
      };
    } else {
      throw {
        error: error.error,
      };
    }
  }
}


export async function signUp(data: SignUpInput): Promise<SignUpResponse> {
  type ServerResponse = SuccessSignUpServerResponse | FailureServerResponse;

  const { data: response } = await axiosInstance.post<ServerResponse>(SIGN_UP_PATH, {
    emailAddress: data.email,
    newPassword: data.password,
    newPasswordConfirm: data.password,
    displayName: 'Tester T.',
    firstName: 'Tester',
    lastName: 'Tes',
    recaptcha_response: data.recaptcha,
  }, {
    params: {
      '__format': 'json',
    },
    method: 'json',
  });

  if (!response.has_errors) {
    return true;
  } else {
    if (response.error === 'workday_user_not_exist') {
      throw {error: 'user-not-exist'};
    } else if (response.error === 'workday_request_failed') {
      throw {error: 'workday_request_failed'}
    } else if (response.invalid_fields.emailAddress![0] === 'Email address is already in use.') {
      throw {
        error: 'user-exist',
      };
    } else {
      throw {
        error: response.error,
      };
    }
  }
}

export async function checkSignUpCode(code: string): Promise<boolean> {
  try {
    await postToJanrain('verify_email_code', {
      verification_code: code,
    });
    return true;
  }
  catch(error) {
    if (error.error === 'invalid_argument' || error.error === 'verification_code_expired') {
      return false;
    } else {
      throw error
    }
  }
}

export async function forgotPassword(data: forgotPasswordInput): Promise<forgotPasswordResponse> {
  const { data: response } = await axiosInstance.post<SuccessForgotPasswordServerResponse |
  FailureForgotPasswordServerResponse>(FORGOT_PASSWORD_PATH, {
    emailAddress: data.email,
    recaptcha_response: data.recaptcha,
  }, {
    params: {
      '__format': 'json',
    },
    method: 'json',
  });

  if (!response.has_errors) {
    return {
      status: response.stat,
    };
  } else {
    if (response.code === 390) {
      throw {
        error: 'maximum_login_attempts',
      };
    } else {
      throw {
        error: response.error,
      }
    };
  }
}

export async function resetPasswordToken(data: string): Promise<resetPasswordTokenRespons> {
  const { data: response } = await axiosInstance.post<SuccessResetPasswordTokenResponse |
  FailureResetPasswordTokenResponse>(TOKEN_RESET_PASSWORD, {
    code: data,
  }, {
    params: {
      '__format': 'json',
    },
    method: 'json',
  });

  if (!response.has_errors) {
    return {
      accessToken: response.access_token,
    };
  } else {
    if(response.code === 413) {
      throw {
        error: 'link_expired'
      }
    }
    throw {
      error: response.error,
    };
  }
}

export async function setNewPassword(data: newPasswordInput): Promise<newPasswordResponse> {
  const { data: response } = await axiosInstance.post<SuccessNewPasswordServerResponse |
  FailureNewPasswordServerResponse>(SET_NEW_PASSWORD_PATH, {
    access_token: localStorage.getItem('accessToken'),
    newPassword: data.password,
  }, {
    params: {
      '__format': 'json',
    },
    method: 'json',
  });

  if (!response.has_errors) {
    return {
      status: response.stat,
    };
  } else {
    throw {
      error: response.error,
    };
  }
}
