
import { AxiosInstance } from "axios";
import { CardPhotoBlock, ComponentWithHeading } from "../../type";
import parseArticleCarouselBlock, { fetchArticlesCarouselData, FilterParams } from "./fetchArticlesCarouselBlock";

type ICarouselInnerParams = Partial<Pick<CardPhotoBlock,
'useContainer' | 'color' | 'cardAlignConfig' | 'articleCardType'>>

export async function fetchCarouselWithHeading(axiosInstance: AxiosInstance,
  filterParams: FilterParams, innerParams?: ICarouselInnerParams): Promise<ComponentWithHeading> {

    const blockResponse = await fetchArticlesCarouselData(axiosInstance, filterParams);

    const ArticleBlockData = parseArticleCarouselBlock(blockResponse, innerParams)

    const { data: [blockData] } = blockResponse.data;

    return {
      type: 'ComponentWithHeading',
      data: {
        title: blockData.attributes.field_header_rich.processed,
        description: blockData.attributes.field_component_description.processed
      },
      component: ArticleBlockData,
    }
  }
