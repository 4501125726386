import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import cs from 'classnames';
import {IconProps} from '../type';
import { WithStyles, createStyles } from '@material-ui/core/styles';

const styles = () => createStyles({
  root: {
    width: 17,
    height: 15
  }
});

export interface Props extends IconProps, WithStyles<typeof styles>{

}

const LinkedInIcon = (props: Props) => {
  const {classes, className} = props;
  return(
    <SvgIcon className={cs(classes.root, className)} viewBox="0 0 17 15">
      <path d="M.897 14.538V4.747h3.061v9.79H.898zm11.256-9.791c2.175 0 3.884 1.408 3.884 4.44v5.445h-3.47V9.565c0-1.326-.544-2.231-1.737-2.231-.914 0-1.422.61-1.658 1.2-.089.21-.075.505-.075.8v5.298H5.658s.045-8.975 0-9.791h3.44v1.537c.202-.672 1.301-1.631 3.055-1.631zM2.29.42c1.13 0 1.824.73 1.846 1.697 0 .949-.717 1.7-1.867 1.7h-.023C1.14 3.818.421 3.07.421 2.121c0-.967.74-1.7 1.87-1.7z" fill="#212121" fillRule="evenodd"/>
    </SvgIcon>
  )
}

export default withStyles(styles)(LinkedInIcon);
