import axiosInstance from './api';
import { BlocksResponseDTO, BlockDTO } from './fetchBlock/types';
import { HeaderData } from '../store/header/type';


type HeaderDataDTO = BlockDTO<"block_content--header_menu", {
  field_link_text: string;
}>

type HeaderIncludeDTO = BlockDTO<'paragraph--menu_link', {
  id: string;
  field_link_href: string;
  field_link_title: string;
}>

type ServerResponse = BlocksResponseDTO<HeaderDataDTO, HeaderIncludeDTO>;

export async function loadHeader(): Promise<HeaderData> {
  const {data: res} = await axiosInstance.get<ServerResponse>(`/block_content/header_menu`, {
    params: {
      include: ['field_links'].join(),
    },
  });

  return {
    logOtButtonText: res.data[0].attributes.field_link_text,
    links: res.included.map((item) => ({
      id: item.id,
      href: item.attributes.field_link_href,
      title: item.attributes.field_link_title,
    })),
  };
}
