import React from 'react'
import SetPasswordForm from '../../components/ReduxForms/SetPasswordForm';
import { LoginInput, setNewPassword, newPasswordInput } from '../../store/auth/action';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { RootState } from '../../store';
import FormWrapper from '../../components/ReduxForms/FormWrapper';
import { NewPasswordStatus } from '../../store/auth/reducer';
import { resetPasswordToken } from '../../services/auth';
import ResetPasswordSuccess from '../responsePages/resetPasswordSuccess';
import qs from 'qs';
import ResetPasswordLinkExpired from '../responsePages/resetPasswordLinkExpired';

interface SetPasswordProps extends RouteComponentProps {
  login: (data: LoginInput) => void
  setPasswordData: any
  isLoading: boolean
  requestStatus: NewPasswordStatus,
  setNewPasswordSubmit: (data: newPasswordInput) => void,
}

class SetPasswordPage extends React.Component<SetPasswordProps> {

  state = {
    error: null,
  }

  componentDidMount() {
    const responseCode = qs.parse(window.location.search.replace(/^\?/, ''));
    if(window.localStorage.getItem('accessToken') === null){
      resetPasswordToken(responseCode.code).then(
        response => {
          localStorage.setItem('accessToken', response.accessToken);
        }
      ).catch(
        error => {
          this.setState({error: error.error})
        }
      );
    }
  }

  cancel = () => {
    this.props.history.push('/');
  }

  render() {
    return !this.props.isLoading && (
      this.state.error !== 'link_expired' ?
        (this.props.requestStatus !=='ok' ?
          <FormWrapper
            title={this.props.setPasswordData.headerText}
            description={this.props.setPasswordData.description}
          >
            <SetPasswordForm
              onSubmit={this.props.setNewPasswordSubmit}
              onCancel={this.cancel}
              data = {this.props.setPasswordData}
            />
          </FormWrapper> :
          <ResetPasswordSuccess />)
        :
        <ResetPasswordLinkExpired />
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.auth.loading,
  setPasswordData: state.auth.resetPasswordForm,
  requestStatus: state.auth.newPasswordStatus,
});

const dispatchToProps = {
  setNewPasswordSubmit: setNewPassword.request,
};

export default withRouter(connect(mapStateToProps, dispatchToProps)(SetPasswordPage));
