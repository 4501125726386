import React from 'react'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import { renderTextField, FormButtons } from '../ReduxFormFields';
import validate from '../common/setPasswordValidate';
import { NEW_PASSWORD_FORM } from '../../../store/auth/action';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => createStyles({
  errorMessage: {
    padding: '1em 0',
    color: theme.palette.primary.main,
  },
});
interface Props extends WithStyles<typeof styles>{
  onCancel: () => void,
  data: any,
}

const SetPasswordForm: React.FunctionComponent<Props & InjectedFormProps<{
  password: string;
  checkPassword: string;
}, Props>> = (props) => {
  const { handleSubmit, pristine, submitting, onCancel, data, classes, error } = props;

  const errorHandler = (error: string) => {
    switch(error) {
      case 'invalid_access_token':
        return <div className={classes.errorMessage}>Invalid access token</div>
      default:
        return <div className={classes.errorMessage}>Something went wrong</div>
    }
  }

  return (
    <form onSubmit={handleSubmit} noValidate>
      {error && errorHandler(error)}
      <Field
        name="password"
        component={renderTextField}
        type="password"
        label={data.placeholders.password}
        required
      />
      <Field
        name="checkPassword"
        component={renderTextField}
        type="password"
        label={data.placeholders.password_repeat}
        required
      />
      <FormButtons
        pristine={pristine}
        submitting={submitting}
        cancelText={data.cancelButtonText}
        submitText={data.buttonText}
        onCancel={onCancel}
      />
    </form>
  )
}

export default withStyles(styles)(reduxForm<{
  password: string;
  checkPassword: string;
}, Props>({
  form: NEW_PASSWORD_FORM,
  validate,
})(SetPasswordForm));
