import * as React from 'react';
import cs from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import { WithStyles, createStyles } from '@material-ui/core/styles';

const styles = () => createStyles({
  container: {
    boxSizing: 'border-box',
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: '-12px',
    marginRight: '-12px'
  },
});

export interface Props extends WithStyles<typeof styles> {
  className?: string,
  children: React.ReactNode,
};

const JnJGridRow = (props: Props) => {
  const { classes, className, children } = props;
  return (
    <div className={cs(className, classes.container)}>
      {children}
    </div>
  );
}

export default withStyles(styles)(JnJGridRow);
