
import { AxiosInstance } from 'axios';
import { ComponentWithHeadingData, ComponentWithHeading } from '../../type';
import { VideoBlockFilterParams, parseVideoBlockData, fetchVideoBlockData } from './fetchCardVideoBlock';
import { BlockDTO } from './types';

type InnerParams = Partial<Pick<ComponentWithHeadingData, 'bgColor'>>;

export async function fetchVideoBlockWithHeading(axiosInstance: AxiosInstance,
  filterParams: VideoBlockFilterParams, innerParams?: InnerParams): Promise<ComponentWithHeading> {
  const blockResponse = await fetchVideoBlockData(axiosInstance, filterParams);

  const videoBlockComponent = parseVideoBlockData(blockResponse);

  const { data: [blockData] } = blockResponse.data;

  return {
    type: "ComponentWithHeading",
    data: {
      title: blockData.attributes.field_header_rich.value,
    },
    component: videoBlockComponent,
    ...innerParams,
  }

}




