import { AxiosInstance, AxiosResponse } from "axios";
import { BlockDTO, BlocksResponseDTO } from "./types";
import { WelcomeHeroBlock } from "../../type";

type WelcomeHeadingBlockDTO = BlockDTO<'block_content--welcome_block', {
  field_body: {
    processed: string,
  },
  field_cta_label: string,
  field_greeting: string,
  field_jnj_user_greeting: string,
  field_your_information: {
    processed: string
  },
  field_heading: string
}>;

function parseWelcomeHeadingBlock(response: AxiosResponse<BlocksResponseDTO<
  WelcomeHeadingBlockDTO>>): WelcomeHeroBlock {
  const [blockData] = response.data.data;
  return {
    type: 'WelcomeHero',
    data: {      
      greeting: blockData.attributes.field_greeting,
      jjUserGreeting: blockData.attributes.field_jnj_user_greeting,
      yourInfo: blockData.attributes.field_your_information.processed,
      welcomeMessage: blockData.attributes.field_heading,
      description: blockData.attributes.field_body.processed,
      label: blockData.attributes.field_cta_label,
    },
    anchorTag: 'getting-ready-block',
  };
}


export async function fetchWelcomeHeadingBlock(axiosInstance: AxiosInstance): Promise<WelcomeHeroBlock> {

  const blockResponse = await axiosInstance.get<BlocksResponseDTO<
  WelcomeHeadingBlockDTO>>('/block_content/welcome_block');

  return parseWelcomeHeadingBlock(blockResponse);
}
