import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

function createSize(fontSize: number, lineHeight: number) {
  return {
    fontSize: fontSize,
    lineHeight: lineHeight/fontSize,
  };
}

const styles = (theme: Theme) => createStyles({
  h1: {
    ...createSize(32, 36),
    [theme.breakpoints.up('md')]: createSize(32, 36),
    [theme.breakpoints.up('lg')]: createSize(60, 64),
  },
  h2: {
    ...createSize(20, 36),
    [theme.breakpoints.up('md')]: createSize(28, 36),
    [theme.breakpoints.up('lg')]: createSize(40, 44),
  },
  h3: {
    ...createSize(18, 24),
    [theme.breakpoints.up('md')]: createSize(28, 36),
    [theme.breakpoints.up('lg')]: createSize(40, 44),
  },
  h4: {
    ...createSize(16, 24),
    [theme.breakpoints.up('md')]: createSize(20, 36),
    [theme.breakpoints.up('lg')]: createSize(26, 32),
  },
  subtitle1: {
    ...createSize(18, 24),
    [theme.breakpoints.up('md')]: createSize(20, 30),
    [theme.breakpoints.up('lg')]: createSize(24, 36),
  },
  subtitle2: createSize(20, 30),
  body1: createSize(16, 24),
  body2: createSize(14, 20),
});

export default withStyles(styles)(Typography);
