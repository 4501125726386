import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import {IconProps} from './type';
import { WithStyles, createStyles } from '@material-ui/core/styles';
import cs from 'classnames';

const styles = () => createStyles({
  root: {
    width: 26,
    height: 20
  }
});

export interface Props extends IconProps, WithStyles<typeof styles>{
  color?: string;
}

const NextIcon = (props: Props) => {
  const {classes, className, color="#fff"} = props;
  return(
    <SvgIcon className={cs(classes.root, className)}>
      <g transform="translate(-12 -14.948)">
        <path d="M32.341 26.388l-18.835.138a1.5 1.5 0 1 1-.012-3l19.184-.14-5.818-5.826a1.5 1.5 0 1 1 2.122-2.12l8.211 8.22a1.5 1.5 0 0 1 0 2.122l-8.314 8.304a1.5 1.5 0 1 1-2.12-2.122l5.582-5.576z" fill={color} fillRule="nonzero"/>
      </g>
    </SvgIcon>
  )
}

export default withStyles(styles)(NextIcon);
