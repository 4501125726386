import * as React from 'react';
import Footer from './components/Footer';
import Header from './components/Header';
import { IntlProvider } from 'react-intl';
import RootContainer from './components/RootContainer/RootContainer';
import { RootRouter } from './pages';
import { connect } from 'react-redux';
import { RootState } from './store';
import ErrorPage from './pages/ErrorPages/ErrorPage';
import { withRouter, RouteComponentProps } from 'react-router-dom';

require('./App.css');

export interface Props extends RouteComponentProps {
  error: any
}

class App extends React.Component<Props> {

  public render() {

    const { error } = this.props

    return (
      <IntlProvider locale="en" defaultLocale="en">
        {error.errorData === null ?
          <RootContainer
            header={<Header />}
            content={<RootRouter />}
            footer={<Footer />}
          />
          :
          <ErrorPage data = {{
              title: "We'll be back soon!",
              description: error.errorData,
            }}
          />
        }
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  error: state.error503,
});

export default withRouter(connect(mapStateToProps)(App));
