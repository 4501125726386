import Loadable from 'react-loadable';
import { Omit } from 'utility-types';
import Loading from '../../components/Loading';

export default function MyLoadable<Props>(opts: Omit<Loadable.OptionsWithoutRender<Props>, 'loading'>
  & Partial<Pick<Loadable.OptionsWithoutRender<Props>, 'loading'>>){
  return Loadable(Object.assign({
    loading: Loading,
    timeout: 10000,
  }, opts));
}
