import React from 'react'
import { connect } from 'react-redux';
import { RootState } from '../../store';
import { ResponseComponent } from '../../components/ResponsePageComponent';
import { ResponseComponentData } from '../../components/ResponsePageComponent/ResponseComponent';

export interface Props {
  responseData: any,
  isLoading: boolean,
  email: string,
}

class ResponseForgotPassword extends React.Component<Props> {

  formatData(): ResponseComponentData {
    const {responseData, email} = this.props;
    return {
      ...responseData,
      description: responseData.description.replace('@mail', email),

    }
  }

  componentDidMount() {
    localStorage.removeItem('accessToken');
  }

  render() {
    return !this.props.isLoading &&
      <ResponseComponent data={this.formatData()} />
  }
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.auth.loading,
  responseData: state.auth.responseForgotPassword,
});

export default connect(mapStateToProps)(ResponseForgotPassword);
