import axios from 'axios';
import Qs from 'qs';
import { restoreAuthData, storeAuthData } from '../store/auth/reducer';
import { Error403 } from '../utils/errors/Error403';
import { ServiceUnavailable } from '../utils/errors/ServiceUnavailable';
import Cookies from 'js-cookie';

let cook = Cookies.get('jnj_welcome_saml');
let cookname = Cookies.get('jnj_welcome_saml_givenname');
let cooksn = Cookies.get('jnj_welcome_saml_sn');

const baseUrl = process.env.REACT_APP_ROOT;

const hostname = window && window.location && window.location.hostname;

export let requestUrl: string = baseUrl;

const hostnameArray = hostname.split('.');

const isDevEnvironment = hostnameArray[0] === 'ui';

if(isDevEnvironment) {
  hostnameArray[0] = 'edit';
  requestUrl = `https://${hostnameArray.join('.')}`;
}

const [username, password] = process.env.REACT_APP_SERVER_AUTH.split(':');

let path: string;

if(localStorage.getItem('language') === null || localStorage.getItem('language') === 'en') {
  path = `${requestUrl}/jsonapi/`
} else {
  path = `${requestUrl}/${localStorage.getItem('language')}/jsonapi/`
}


if (cook) {
  // get the cookie timestamp from server (backend)
  let expiresInValue = Number(cook) * 1000;

  // check for valid timestamp
  let valid = (new Date(expiresInValue)).getTime() > 0;

  // remove the cookie timestamp from server (backend)
  Cookies.remove('jnj_welcome_saml', { domain: '.' + hostname });
  Cookies.remove('jnj_welcome_saml', { domain: hostname });
  Cookies.remove('jnj_welcome_saml_givenname', { domain: '.' + hostname });
  Cookies.remove('jnj_welcome_saml_givenname', { domain: hostname });
  Cookies.remove('jnj_welcome_saml_sn', { domain: '.' + hostname });
  Cookies.remove('jnj_welcome_saml_sn', { domain: hostname });
  document.cookie = "jnj_welcome_saml=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  document.cookie = "jnj_welcome_saml_givenname=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  document.cookie = "jnj_welcome_saml_sn=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

  if (valid) {
    storeAuthData({
      accessToken: 'none',
      expiresIn: expiresInValue,
      refreshToken: 'none',
      userData: {
        name: '' + cookname,
        lastName: '' + cooksn,
        managerEmail: '',
        managerFamilyName: '',
        managerPosition: '',
        position: '',
        date: null,
        email: '',
        managerName: '',
        isJJUser: true,
      },
    });
  }
  cook = cookname = cooksn = undefined;
  expiresInValue = 0;
  valid = false;
}

const axiosInstance = axios.create({
  baseURL: path,
  headers: {
    'Accept': 'application/json',
  },
  auth: {
    username,
    password,
  },
  paramsSerializer: (params) => Qs.stringify(params, {arrayFormat: 'brackets'}),
  transformResponse: (data) => {
    // E11 hack
    if(typeof data === 'string') {
      try {
        data=JSON.parse(data)
      }
      catch(error) {}
    }
    return data;
  },
});

axiosInstance.interceptors.request.use((config) => {
  const login = restoreAuthData();

  return login !== null ? {
    ...config,
    headers: {
      ...config.headers,
    },
  }
  : config;
});

axiosInstance.interceptors.response.use(undefined, (error) => {
  if (!error.response) {
    return Promise.reject(new ServiceUnavailable())
  } else if (error.response.status === 403) {
    return Promise.reject(new Error403());
  } else if (error.response.status === 503) {
    return Promise.reject(new ServiceUnavailable(error.response.data))
  } else {
    return Promise.reject(error);
  }
});

export default axiosInstance;
