import { AxiosInstance, AxiosResponse } from "axios";
import { BlockDTO, BlocksResponseDTO, DrupalHtmlDTO } from "./types";
import { BottomLinkedBlock } from "../../type";

type BottomBlockDTO = BlockDTO<'node--component_bottom_block', {
  field_component_sub_title: string;
  field_component_link_path: string;
  field_component_link_text: string;
  field_cta_link_title: string;
  field_token_link: string;
  field_component_title_formatted: DrupalHtmlDTO;
}>;

function parseBottomBlock(response: AxiosResponse<BlocksResponseDTO<BottomBlockDTO>>): BottomLinkedBlock {
  const [blockData] = response.data.data;

  return {
    type: 'BottomSection',
    data: {
      title: blockData.attributes.field_component_title_formatted.value.replace
      ('@link',  ''),
      link: blockData.attributes.field_component_link_path,
      text: blockData.attributes.field_component_sub_title,
      fieldCta: blockData.attributes.field_cta_link_title,
      titleLinkText: blockData.attributes.field_component_link_text,
    }
  };
}

export async function fetchBottomBlock(axiosInstance: AxiosInstance, pageId: string): Promise<BottomLinkedBlock> {
  const blockResponse = await axiosInstance.get<BlocksResponseDTO<BottomBlockDTO>>('/node/component_bottom_block', {
    params: {
      filter: {
        'field_component_page': pageId,
      },
    },
  });

  return parseBottomBlock(blockResponse);
}
