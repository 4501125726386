import { BlocksResponseDTO, BlockDTO, ImageBlockDTO } from "./types";
import { AxiosInstance, AxiosResponse } from "axios";
import { CardPhotoBlock } from "../../type";
import { requestUrl } from "../api";

type ArticlesCarouselBlockDTO = BlockDTO<'node--component_articles_slider', {
  field_cta_link_title: string;
  field_component_picture_align: 'left' | 'right';
  field_component_description: {
    processed: string,
  },
  field_header_rich: {
    processed: string
  }
}>;

type ArticleBlockDTO = BlockDTO<'node--article', {
  field_article_teaser_title: string;
  field_header: string;
  field_article_reference_cta: string;
  field_article_person_name?: string;
  field_article_person_position?: string;
  field_article_teaser_summary: {
    processed: string,
  }
  field_article_path: string,
}, {
  field_image_thumbnail: {
    data: {
      id: string;
    };
  };
}>

type ICarouselInnerParams = Partial<Pick<CardPhotoBlock,
'useContainer' | 'color' | 'cardAlignConfig' | 'articleCardType'>>
export interface FilterParams {
  'field_component_page': string,
  'field_weight'?: number;
}

export async function fetchArticlesCarouselBlock(axiosInstance: AxiosInstance,
  filterParams: FilterParams, innerParams?: ICarouselInnerParams): Promise<CardPhotoBlock> {
  const blockData = await fetchArticlesCarouselData(axiosInstance, filterParams);
  return parseArticleCarouselBlock(blockData, innerParams)
}


export default function parseArticleCarouselBlock(response: AxiosResponse<BlocksResponseDTO<ArticlesCarouselBlockDTO,
  ArticleBlockDTO | ImageBlockDTO>>, innerParams?: ICarouselInnerParams): CardPhotoBlock {
  const {
    data: [ blockData ],
    included: paragraphsData,
  } = response.data;

  type AgrType = {
    imageMap: Record<string, ImageBlockDTO>;
    articlesBlocks: ArticleBlockDTO[];
  };

  const {
    imageMap = {},
    articlesBlocks = [],
    }: AgrType = paragraphsData.reduce((agr: AgrType, record) => {
      switch(record.type) {
        case 'file--file':
          return {
            ...agr,
            imageMap: {
              ...agr.imageMap,
              [record.id]: record,
            },
          };
        case 'node--article':
          return {
            ...agr,
            articlesBlocks: [
              ...agr.articlesBlocks,
              record,
            ],
          };
      }
    },
    {
      imageMap: {},
      articlesBlocks: [],
    }
  );

  return {
    type: 'CardPhotoBlock',
    data: {
      ratio: '1/1',
      rtl: blockData.attributes.field_component_picture_align === 'left',
      stories: articlesBlocks.map((article) => (
        {
          fieldCta: blockData.attributes.field_cta_link_title,
          headerInfo: article.attributes.field_article_teaser_title || article.attributes.field_header,
          description: article.attributes.field_article_teaser_summary !== null ?
          article.attributes.field_article_teaser_summary.processed : undefined,
          imgSrc: requestUrl + imageMap[article.relationships.field_image_thumbnail.data.id].attributes.uri.url,
          link: `/article/${article.attributes.field_article_path}`,
          managerName: article.attributes.field_article_person_name,
          managerPosition: article.attributes.field_article_person_position,
        }
      )),
    },
    ...innerParams
  };
}

export async function fetchArticlesCarouselData(axiosInstance: AxiosInstance,
  filterParams: FilterParams): Promise<AxiosResponse<BlocksResponseDTO<ArticlesCarouselBlockDTO,
  ArticleBlockDTO | ImageBlockDTO>>>{
  return await axiosInstance.get<BlocksResponseDTO<ArticlesCarouselBlockDTO,
  ArticleBlockDTO | ImageBlockDTO>>
    ('/node/component_articles_slider', {
      params: {
        filter: filterParams,
        include: [
          'field_component_articles',
          'field_component_articles.field_image_thumbnail',
        ].join(','),
      },
    });
  }
