import React from 'react'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import withStyles from '@material-ui/core/styles/withStyles';
import { WithStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { renderTextField, renderReCaptchaField, FormButtons } from '../ReduxFormFields';
import validate from '../common/loginValidate';
import { LoginInput, LOGIN_FORM } from '../../../store/auth/action';

const styles = (theme: Theme) => createStyles({
  linkWrapper: {
    textAlign: 'right'
  },
  forgotPassLink: {
    fontSize: 14,
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  errorMessage: {
    padding: '1em 0',
    color: theme.palette.primary.main,
  },
});

interface Props extends WithStyles<typeof styles> {
  onCancel: ()=>void;
  data: any,
}

const LoginForm: React.FunctionComponent<Props & InjectedFormProps<LoginInput, Props>> = (props) => {
  const {
    handleSubmit,
    pristine,
    submitting,
    onCancel,
    classes,
    error,
    data,
  } = props

  const errorHandler = (error: string) => {
    switch(error) {
      case 'invalid_credentials':
      case 'user_missing_or_blocked':
        return <div className={classes.errorMessage}>{data.validation.credentials_incorrect}</div>
      case 'maximum_login_attempts':
        return <div className={classes.errorMessage}>{data.validation.maximum_login_attempts}</div>
      default:
        return <div className={classes.errorMessage}>Something went wrong</div>
    }
  }

  return (
    <form onSubmit={handleSubmit} noValidate>
      {error && errorHandler(error)}
      <Field
        name="email"
        component={renderTextField}
        type="email"
        label={data.placeholders.email}
        required
      />
      <Field
        name="password"
        component={renderTextField}
        type="password"
        label={data.placeholders.password}
        required
      />
      <div className={classes.linkWrapper}>
        <Link to="/login/forgot-password" className={classes.forgotPassLink}>
          {data.forgotPasswordText}
        </Link>
      </div>
      <Field
        name="recaptcha"
        component={renderReCaptchaField}
        required
      />
      <FormButtons
        pristine={pristine}
        submitting={submitting}
        cancelText={data.cancelButtonText}
        submitText={data.buttonText}
        onCancel={onCancel}
      />
    </form>
  )
}

export default withStyles(styles)(reduxForm<LoginInput, Props>({
  form: LOGIN_FORM,
  validate,
})(LoginForm))
