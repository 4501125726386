import * as React from 'react';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core/styles';
import { FormControl, Select, MenuItem } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {
    fontFamily: theme.typography.fontFamily,
    paddingLeft: 13,
    backgroundColor: '#fff',
    maxWidth: 90,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 'none',
    },
    '&:hover': {
      cursor: 'pointer',
    }
  },
  list: {
    borderBottom: '2px solid black',
    backgroundColor: '#fff',
    fontSize: 14,
    fontWeight: 300,
    lineHeight: 1.75,
    '&:before, &:after': {
      border: 'none !important',
    },
  },
  listItem: {
    color: '#212121',
    fontSize: 14,
    fontWeight: 300,
    lineHeight: 1.75,
    padding: 10,
    '&:hover': {
      cursor: 'pointer',
    }
  },
});

interface Props extends WithStyles<typeof styles> {
  data: object
}

interface State {
  name: string,
  language: string | null,
}

class LanguageSelector extends React.Component<Props, State> {

  state = {
    language: localStorage.getItem('language'),
    name: 'English',
  };

  handleChange = () => (event:any) => {
    this.setState({
      language: event.target.value,
    }, () => {
      this.changeLanguage()
    });
  };

  changeLanguage = () => {
    localStorage.setItem('language', this.state.language ? this.state.language : 'en');
    window.location.reload();
  }

  componentDidMount() {
    if(!localStorage.getItem('language')){
      let checkValue: string;
      if(navigator.language.toLowerCase() === 'fr-ca'){
        checkValue = navigator.language.toLowerCase()
      } else {
        checkValue = navigator.language.substring(0,2);
      }
      for(const key of Object.keys(this.props.data)){
        if(key.includes(checkValue)){
          this.setState({
            language: key,
          }, () => {
            this.changeLanguage()
          })
          return true
        }
      }
    }
  }

  render() {

    const {
      classes,
      data,
    } = this.props;

    return (
      <FormControl className={classes.root}>
        <Select
          value={this.state.language ? this.state.language : 'en'}
          onChange={this.handleChange()}
          className={classes.list}
          inputProps={{
            name: 'language',
            id: 'language-native-simple',
          }}
        >
          {Object.keys(data).map(key => (
            <MenuItem key={key} value={key} className={classes.listItem}>{data[key]}</MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }
}

export default withStyles(styles)(LanguageSelector);
