import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { connect } from "react-redux";
import qs from 'qs';
import { RootState } from "../store";

interface AuthRoute extends RouteProps {
  isAuthenticated: boolean;
}

const UnauthenticatedRoute: React.SFC<AuthRoute>= ({
  component: Component,
  render = () => null,
  isAuthenticated,
  ...rest
}) =>
  <Route
    {...rest}
    render={props => {
      const {redirect = '/'} =  qs.parse(props.location.search.replace(/^\?/, ''));
      return !isAuthenticated
        ? Component ? React.createElement(Component, props) : render(props)
        : <Redirect to={redirect} />}
    }
  />;

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: state.auth.isLogged,
});

export default connect(mapStateToProps)(UnauthenticatedRoute);
