import { emailRegExp } from "./regExp";
import { LoginInput } from "../../../store/auth/action";

type Errors = {
  email?: string;
  password?: string;
  recaptcha?:string,
}

const validate = (values: LoginInput, props: any): Errors => {

  const errors: Errors = {}

  if(!values.recaptcha) {
    errors.recaptcha = props.data.validation.captcha
  }
  if(!emailRegExp.test(values.email)) {
    errors.email = props.data.validation.email_not_valid
  }
  if(!values.password) {
    errors.password = props.data.validation.empty_password
  }
  return errors
}

export default validate
