import * as React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
// due to typescript requires
require('./JnJThemeProvider.css');

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: ['"J&J Circular"', 'sans-serif'].join(','),
    h1: {
      fontSize: 44,
      lineHeight: 48 / 44,
      fontWeight: 900,
    },
    h2: {
      fontSize: 36,
      lineHeight: 42 / 36,
      fontWeight: 900,
    },
    h3: {
      fontSize: 30,
      lineHeight: 1,
      fontWeight: 900,
    },
    h4: {
      fontSize: 20,
      lineHeight: 26 / 20,
      fontWeight: 900,
    },
    h5: {
      fontSize: 16,
      lineHeight: 22 / 16,
      fontWeight: 900,
    },
    body1: {
      fontSize: 16,
      lineHeight: 1.625,
      fontWeight: 300,
    },
    body2: {
      fontSize: 20,
      lineHeight: 1.5,
      fontWeight: 300,
    },
    caption: {
      fontSize: 12,
      fontWeight: 'bold',
      letterSpacing: 3,
      lineHeight: 15 / 12
    }
  },
  palette: {
    background: {
      default: '#f3f3f3'
    },
    primary: {
      main: '#ca001b',
      dark: '#a50016',
    },
    secondary: {
      main: '#000099'
    },
    divider: '#e6e6e6',
    action: {
      disabled: '#d8d8d8',
      disabledBackground: '#d8d8d8',
  },
    text: {
      primary: '#212121',
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 760,
      lg: 1020,
      xl: 1440,
    }
  }
});

type JnJThemeProviderProps = {
  children: React.ReactNode
};

export default function JnJThemeProvider(props: JnJThemeProviderProps) {
  return <MuiThemeProvider theme={theme}>
    <CssBaseline />
    {props.children}
  </MuiThemeProvider>
}
