import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import cs from 'classnames';
import {IconProps} from './type';
import { WithStyles, createStyles } from '@material-ui/core/styles';

const styles = () => createStyles({
  root: {
    width: 26,
    height: 15
  }
});

export interface Props extends IconProps, WithStyles<typeof styles>{

}

const AngleDownIcon = (props: Props) => {
  const {classes, className} = props;
  return(
    <SvgIcon className={cs(classes.root, className)} viewBox="0 0 26 15">
      <defs>
        <path id="AngleDownIcon" d="M34.063 18.443a1.5 1.5 0 1 1 2.12 2.123L24.865 31.875a1.5 1.5 0 0 1-2.121-.001L11.439 20.56a1.5 1.5 0 0 1 2.122-2.12l10.244 10.252 10.258-10.249z"/>
      </defs>
      <use fill="#212121" transform="translate(-11 -18)" xlinkHref="#AngleDownIcon"/>
    </SvgIcon>
  )
}

export default withStyles(styles)(AngleDownIcon);
