import axiosInstance from './api';
import { BlockDTO, BlocksResponseDTO } from './fetchBlock/types';
import { FooterData } from '../store/footer/type';

type FooterDataDTO = BlockDTO<"block_content--footer_menu", {
  field_copy: string,
  field_facebook_link: string,
  field_instagram_link: string,
  field_linkedin_link: string,
  field_twitter_link: string,
  field_youtube_link: string,
  languages: object,
}>

type FooterIncludeDTO = BlockDTO<'paragraph--menu_link', {
  field_link_href: string,
  field_link_title: string,
}>

type ServerResponse = BlocksResponseDTO<FooterDataDTO, FooterIncludeDTO>;

export async function loadFooter(): Promise<FooterData> {
  const {data: res} = await axiosInstance.get<ServerResponse>(`/block_content/footer_block`, {
    params: {
      include: ['field_links'].join(),
    }
  });

  return {
    copyright: res.data[0].attributes.field_copy,
    facebookLink: res.data[0].attributes.field_facebook_link,
    instagramLink: res.data[0].attributes.field_instagram_link,
    linkedinLink: res.data[0].attributes.field_linkedin_link,
    twitterLink: res.data[0].attributes.field_twitter_link,
    youtubeLink: res.data[0].attributes.field_youtube_link,
    languages: res.data[0].attributes.languages,
    links: res.included.map(item => ({
      linkHref: item.attributes.field_link_href,
      linkTitle: item.attributes.field_link_title,
    })),
  }
}
