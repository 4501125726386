import React from 'react'
import { JnJButton, Typography } from '../../jnj-ui-components';
import withStyles from '@material-ui/core/styles/withStyles';
import { WithStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import cs from 'classnames';
import HtmlSinitizer from '../HtmlSanitizer/HtmlSinitizer';
import ScrollToTopOnMount from '../ScrollRestoration/ScrollToTopOnMount';

const styles = (theme: Theme) => createStyles({
  root: {
    backgroundColor: '#fff',
    paddingTop: 20,
    width: '100%',
    paddingBottom: 50,
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.up('lg')]: {
      flexGrow: 'inherit',
    }
  },
  contentWrapper: {
    width: '100%',
    maxWidth: 500,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 10,
    [theme.breakpoints.up('lg')]: {

    },
  },
  title: {
    paddingBottom: 20,
    marginBottom: 20,
  },
  buttonWrapper: {
    display: 'flex',
    marginTop: 25,
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 70,
    },
  },
  proceedButton: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      maxWidth: 200,
    }
  },
  secondButton: {
    marginTop: 15,
    [theme.breakpoints.up('lg')]: {
      marginLeft: 10,
      marginTop: 0,
    }
  },
  paragraph: {
    paddingBottom: 20,
  }
});

export type ResponseComponentData = {
  headerText: string;
  description: string;
  buttonText?: string;
  secondButtonText?: string;
}

interface Props extends WithStyles<typeof styles> {
  data: ResponseComponentData;
  linkHref?: string,
  secondLinkHref?: string
}

class ResponseComponent extends React.Component<Props> {

  state = {
    success: true,
  }

  render() {

    const {classes, data, linkHref, secondLinkHref} = this.props;

    const description = data.description.split('\n');

    return (
      <div className={classes.root}>
        <ScrollToTopOnMount />
        <div className={classes.contentWrapper}>
          <Typography variant="h2" className={classes.title}>
            {data.headerText}
          </Typography>
          {description.map((paragraph, index) =>
            <div className={classes.paragraph} key={index}>
              <HtmlSinitizer content={paragraph}/>
            </div>)
          }
          <div className={classes.buttonWrapper}>
            {data.buttonText && (linkHref && linkHref.indexOf('http') === -1 ?
              <JnJButton
                className={classes.proceedButton}
                fullWidth
                color="primary"
                variant="contained"
                component={Link as any}
                  {...{ to: linkHref }}
              >
                {data.buttonText}
              </JnJButton> :
              <JnJButton
                className={classes.proceedButton}
                fullWidth
                color="primary"
                variant="contained"
                target="_blank"
                component="a"
                href={linkHref}
              >
                {data.buttonText}
              </JnJButton>
            )}
            {data.secondButtonText && secondLinkHref &&
              <JnJButton
                className={cs(classes.proceedButton, classes.secondButton)}
                fullWidth
                color="primary"
                variant="outlined"
                component={Link as any}
                  {...{ to: secondLinkHref }}
              >
                {data.secondButtonText}
            </JnJButton>}
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(ResponseComponent);
