import {Reducer} from 'redux';
import * as actions from './action';
import { getType, ActionType } from 'typesafe-actions';
import { HeaderData } from './type';


type State =  {
  readonly headerData?: HeaderData;
  readonly loading: boolean;
}

type Actions = ActionType<typeof actions>;

const initialState: State = {
  loading: true,
};

export const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch(action.type) {
    case getType(actions.fetchHeader.request):
      return {
        ...state,
        loading: true,
      };
    case getType(actions.fetchHeader.success):
      return {
        ...state,
        headerData: action.payload,
        loading: false,
      };
    case getType(actions.fetchHeader.failure):
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
