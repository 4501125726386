import { BlocksResponseDTO, BlockDTO } from "./types";
import { AxiosInstance } from "axios";
import { TwoColumnBlock, IWhatAndHowBlockData } from "../../type";

type TwoColumnBlockCareerPathDTO = BlockDTO<'node--component_simple', {
  field_header_rich: {
    processed: string;
  };
}, {
  field_component_simple_paragraph: {
    data: Array<{
      id: string;
      type: string;
    }>;
  }
}>;

type GoalComponentSimpleParagraphDTO = BlockDTO<'paragraph--component_block_description', {
  field_component_description: string;
  field_component_title: string;
}>

interface FilterWeight {
  leftBlockWeight: number,
  rightBlockWeight: number,
}

export async function fetchTwoColumnBlockCareerPath(axiosInstance: AxiosInstance, pageId: string, blocksWeight: FilterWeight): Promise<TwoColumnBlock> {
  const blockResponse = await axiosInstance.get
    <BlocksResponseDTO<TwoColumnBlockCareerPathDTO, GoalComponentSimpleParagraphDTO>>
    ('/node/component_simple', {
      params: {
        filter: {
          'field_component_page': pageId,
          'field_component_type': 'multi_column',
          'field_weight': {
            operator: 'IN',
            value: {
              '0': blocksWeight.leftBlockWeight,
              '1': blocksWeight.rightBlockWeight,
            },
          },
        },
        include: [
          'field_component_simple_paragraph',
        ].join(),
      },
    });

  const {
    data: blockData,
    included: paragraphsData,
  } = blockResponse.data;

  type LeftRightBlock = IWhatAndHowBlockData;

  const filteredBlockData = blockData.filter(el => el.relationships.field_component_simple_paragraph.data.length > 0);

  const mappedLeftRightBlock = filteredBlockData.reduce((agr: LeftRightBlock[], record) => {

    const block: LeftRightBlock = {
      title: '',
      list: [],
    };

    block.title = record.attributes.field_header_rich.processed;

    const dataIds = record.relationships.field_component_simple_paragraph.data.map(el => el.id);

    block.list = paragraphsData.reduce((result: Array<{ header: string; description: string }>, paragraph) => {

      if (dataIds.indexOf(paragraph.id) !== -1) {
        const listItem = {
          header: paragraph.attributes.field_component_title,
          description: paragraph.attributes.field_component_description,
        }
        result.push(listItem);
      }
      return result;
    }, []);

    if (block.title.length > 0) {
      agr.push(block);
    }

    return agr;
  }, []);

  const [leftBlock, rightBlock] = [mappedLeftRightBlock[0], mappedLeftRightBlock[1]];

  return {
    type: 'TwoColumnBlock',
    dataLeft: {
      type: "WhatAndHowBlock",
      data: {
        title: leftBlock.title,
        list: leftBlock.list.map((listEl) => (
          {
            header: listEl.header,
            description: listEl.description,
          }
        )),
        color: "secondary",
      }
    },
    dataRight: {
      type: "WhatAndHowBlock",
      data: {
        title: rightBlock.title,
        list: rightBlock.list.map((listEl) => (
          {
            header: listEl.header,
            description: listEl.description,
          }
        )),
        color: 'primary',
      }
    },
    leftBlockGridConfig: {
      xs: 4,
      xl: 4,
    },
    rightBlockGridConfig: {
      xs: 4,
      xl: 8,
    }
  };

}
