import React from 'react'
import ReCAPTCHA from "react-google-recaptcha";
import { Theme, createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import { WrappedFieldProps } from 'redux-form';
import cs from 'classnames';

function isFakeCaptcha() {
  return process.env.REACT_APP_CAPTCHA_SITE_KEY === 'none';
}

const styles = (theme: Theme) => createStyles({
  captchaField: {
    marginTop: 20,
    marginBottom: 20,
  },
  errorMessage: {
    color: theme.palette.primary.main,
    fontSize: 12,
  },
  fakeCaptcha: {
    fontFamily: theme.typography.fontFamily,
    width: 250,
    height: 60,
    backgroundColor: theme.palette.secondary.main,
    fontSize: 16,
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
});

export interface ReCaptchaFieldProps extends WithStyles<typeof styles>, WrappedFieldProps {

}

class renderReCaptchaField extends React.Component<ReCaptchaFieldProps>  {
  captcha: ReCAPTCHA | null = null;

  componentDidMount() {
    if(isFakeCaptcha()) {
      this.props.input.onChange('some fake key');
    }
  }

  componentDidUpdate() {
    if (this.captcha && this.props.meta.submitting) {
      this.captcha.reset();
    }
  }

  render() {
    const { meta: { touched, error }, classes } = this.props;
    return !isFakeCaptcha() ?
      <div className={classes.captchaField}>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
          onChange={this.props.input.onChange}
          ref={(ref) => this.captcha = ref}
        />
        {touched && error && <span className={classes.errorMessage}>{error}</span>}
      </div> :
      <div className={cs(classes.fakeCaptcha, classes.captchaField)}>
        Fake reCaptcha block
      </div>
  }
}

export default withStyles(styles)(renderReCaptchaField) as 'input' & React.ComponentType<ReCaptchaFieldProps>;
