import { BlocksResponseDTO, BlockDTO } from "./types";
import { AxiosInstance } from "axios";
import { GettingReadyBlock } from "../../type";

type GettingReadyBlockDTO = BlockDTO<'node--component_simple', {
  field_header_rich: {
    processed: string
  }
}>;

type FieldComponentSimpleParagraphDTO = BlockDTO<'paragraph--component_items_list', {
  field_component_items: string[];
  field_component_title: string;
}>


export async function fetchGetReadyBlock(axiosInstance: AxiosInstance, pageId: string): Promise<GettingReadyBlock> {
  const blockResponse = await axiosInstance.get
    <BlocksResponseDTO<GettingReadyBlockDTO, FieldComponentSimpleParagraphDTO>>
    ('/node/component_simple', {
    params: {
      filter: {
        'field_component_page': pageId,
      },
      include: [
        'field_component_simple_paragraph',
      ].join(),
      fields: {
        'field_component_simple_paragraph': [
          'field_component_title',
          'field_component_items',
        ].join(),
      },
    },
  });

  const {
    data: [ blockData ],
    included: paragraphsData,
   } = blockResponse.data;

  return {
    type: 'GettingReady',
    data: {
      title: blockData.attributes.field_header_rich.processed,
      items: paragraphsData.map(({attributes}) => ({
        title: attributes.field_component_title,
        list: attributes.field_component_items,
      })),
    },
    rootId: 'getting-ready-block',
  };

}
