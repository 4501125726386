import { call, take, put, fork } from 'redux-saga/effects';
import { getType, ActionType } from 'typesafe-actions';
import * as actions from './action';
import { fetch } from '../../services/page';
import { show503Error } from '../503error/action';
import { ServiceUnavailable } from '../../utils/errors/ServiceUnavailable';

function* loadPageContent(pageID: string) {
  try {
    yield put(actions.fetchAction.request({pageID}));
    const data = yield call(fetch, pageID);
    yield put(actions.fetchAction.success({pageID, data}));
  }
  catch(error) {
    if(error instanceof ServiceUnavailable) {
      yield put(show503Error(error.response));
    }
    yield put(actions.fetchAction.failure({pageID, error: "Fetch page error"}));
    throw error;
  }
}

export function* saga() {
  while(true) {
    const action: ActionType<typeof actions.initAction> = yield take(getType(actions.initAction));
    yield call(loadPageContent, action.payload);
  }
}
