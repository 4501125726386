import * as React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { connect } from "react-redux";
import { RootState } from "../store";
import { ErrorAccessPage } from "../pages/ErrorPages";

interface AuthRoute extends RouteProps {
  isAuthenticated: boolean;
}

const AuthenticatedRoute: React.SFC<AuthRoute> =  ({
  component: C,
  render = () => null,
  isAuthenticated,
  ...rest
}) =>
  <Route
    {...rest}
    render={props =>
      isAuthenticated
        ? C ? React.createElement(C, props) : render(props)
        : <ErrorAccessPage redirectLink={`/login/form?redirect=${rest.path}`}/>
      }
  />;

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: state.auth.isLogged,
});

export default connect(mapStateToProps)(AuthenticatedRoute);
