
import {Reducer} from 'redux';
import { getType, ActionType } from 'typesafe-actions';
import * as actions from './action';
import { IWelcomeBlocks } from '../../type';

type State =  Record<string, {
  isLoading: boolean;
  data: IWelcomeBlocks[] | null;
  error?: string;
}>

type Actions = ActionType<typeof actions>;

const initialState: State = {

};

export const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch(action.type) {
    case getType(actions.initAction):
      return {
        ...state,
        [action.payload]: {
          isLoading: false,
          data: null,
          ...state[action.payload],
        }
      };

    case getType(actions.destructAction):
      let newState = {...state};
      delete newState[action.payload];
      return newState;

    case getType(actions.fetchAction.request):
      return {
        ...state,
        [action.payload.pageID]: {
          ...state[action.payload.pageID],
          isLoading: state[action.payload.pageID].data ? false : true,
        }
      };

    case getType(actions.fetchAction.success):
      return {
        ...state,
        [action.payload.pageID]: {
          ...state[action.payload.pageID],
          isLoading: false,
          data: action.payload.data
        }
      };

    case getType(actions.fetchAction.failure):
      return {
        ...state,
        [action.payload.pageID]: {
          ...state[action.payload.pageID],
          isLoading: false,
          error: action.payload.error
        }
      };


    default:
      return state;
  }
};
