import React from 'react'
import RegisterForm from '../../components/ReduxForms/RegisterForm';
import { withRouter, RouteComponentProps } from "react-router";
import { connect } from 'react-redux';
import { RootState } from '../../store';
import { SignUpInput, signUp, clearRegisterStatus } from '../../store/auth/action';
import { RegisterStatus } from '../../store/auth/reducer';
import RegistrationResponseSuccess from '../responsePages/registrationResponseSuccess';
import RegistrationResponseUserNotExist from '../responsePages/registrationResponseUserNotExist';
import RegistrationUserExist from '../responsePages/registrationResponseUserExist';
import FormWrapper from '../../components/ReduxForms/FormWrapper';

interface Props extends RouteComponentProps<{}> {
  signUp: (data: SignUpInput) => void;
  clearRegisterStatus: () => void;
  registerFormData: any,
  isLoading: boolean,
  registerStatus?: RegisterStatus;
}

interface State {
  userExist: boolean;
  email: string;
}

class RegisterPage extends React.Component<Props, State> {
  state = {
    userExist: false,
    email: '',
  }

  submit = (values: SignUpInput) => {
    // print the form values to the console
    this.props.signUp(values);
    this.setState({ email: values.email })
  }

  cancel = () => {
    this.props.history.goBack()
  }

  componentWillUnmount() {
    this.props.clearRegisterStatus();
  }

  render() {
    switch(this.props.registerStatus) {
      case 'success':
        return <RegistrationResponseSuccess email={this.state.email}/>;
      case 'user-exist':
        return <RegistrationUserExist />;
      case 'user-not-exist':
        return <RegistrationResponseUserNotExist/>;
      default:
        return  !this.props.isLoading &&
          <FormWrapper
            title={this.props.registerFormData.headerText}
            description={this.props.registerFormData.description}
          >
            <RegisterForm
              onSubmit={this.submit}
              onCancel={this.cancel}
              data={this.props.registerFormData} />
          </FormWrapper>
    }
  }
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.auth.loading,
  registerFormData: state.auth.registrationForm,
  registerStatus: state.auth.registerStatus,
});

const dispatchToProps = {
  clearRegisterStatus,
  signUp: signUp.request,
};

export default withRouter(connect(mapStateToProps, dispatchToProps)(RegisterPage))
