import { AxiosInstance, AxiosResponse } from "axios";
import { BlockDTO, BlocksResponseDTO, DrupalHtmlDTO, IQueryParams } from "./types";
import { TwoColumnBlock } from "../../type";
import { fetchArticlesCarouselBlock } from "./fetchArticlesCarouselBlock";
import { CardPhotoBlock } from "../../blocks/Card";

type HeadingBlockDTO = BlockDTO<'node--component_simple', {
  field_header_rich: {
    value: string
  };
  field_component_description: {
    processed: string
  }
}>;

type TabFieldBlockDTO = BlockDTO<'paragraph--component_items_list', {
  field_component_items: Array<string>
}>;

type QueryParams = IQueryParams<{
  'field_component_page': string;
  'field_weight'?: number;
}>

function parseLeadershipBlock(leftBlockResponse: AxiosResponse<BlocksResponseDTO<HeadingBlockDTO,
  TabFieldBlockDTO>>, rightBlockData: CardPhotoBlock): TwoColumnBlock {

    const rightBlock = rightBlockData;

  const {
    data: [ blockData ],
    included: [ paragraphsData ],
   } = leftBlockResponse.data;

  return {
    type: 'TwoColumnBlock',
    dataLeft: {
      type: 'ComponentWithHeading',
        data: {
          title: blockData.attributes.field_header_rich.value,
          description: blockData.attributes.field_component_description.processed,
          bgColor: 'grey',
        },
        component: {
          type: 'TwoColumnList',
          data: paragraphsData.attributes.field_component_items,
          bold: true,
          useContainer: true,
        },
    },
    dataRight: rightBlock,
    leftBlockGridConfig:{
      xs: 4,
      xl: 9,
    },
    rightBlockGridConfig:{
      xs: 4,
      xl: 3,
    },
  }
}


export async function fetchLeadershipBlock(axiosInstance: AxiosInstance,
  queryParams?: QueryParams): Promise<TwoColumnBlock> {

  const leftBlockResponse = await axiosInstance.get<BlocksResponseDTO<HeadingBlockDTO,
  TabFieldBlockDTO>>('/node/component_simple', {
    params: {
      ...queryParams,
    },
  });

  const rightBlockData = await fetchArticlesCarouselBlock(axiosInstance,
    {
      'field_component_page': 'your-community',
      'field_weight': 0,
    },
    {
      useContainer: false,
      color: 'secondary',
      cardAlignConfig: {
        xs: "vertical",
        lg: "horizontal",
      },
      articleCardType: "small",
    }
  );

  return parseLeadershipBlock(leftBlockResponse, rightBlockData);
}
