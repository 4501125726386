import axiosInstance from './api';
import { BlockDTO, BlocksResponseDTO } from './fetchBlock/types';
import { CookiesData } from '../store/cookies/reducer';

export type CookiesDataDTO = BlockDTO<"block_content--cookie_policy", {
  field_body: {
    value: string;
  };
  field_cta_label: string;
  field_link_path: string;
  field_link_text: string;
  field_token_link: string;
}>

export async function loadCookies(): Promise<CookiesData> {
  const res = await axiosInstance.get<BlocksResponseDTO<CookiesDataDTO>>(`/block_content/cookie_policy`);
  const [data] = res.data.data;
  return {
    information: data.attributes.field_body.value.replace('@link',  '{linkToArticle}'),
    acceptButtonText: data.attributes.field_cta_label,
    cookiePolicyLink: data.attributes.field_link_path,
    cookiePolicyText: data.attributes.field_link_text,
  }
}
