import * as React from 'react';
import { LoadingComponentProps } from 'react-loadable';
import { JnJButton } from '../../jnj-ui-components';

interface LoadingProps extends LoadingComponentProps {
  className?: string;
}

const Loading: React.SFC<LoadingProps> = (props) => {
  if (props.error) {
    return <div className={props.className}>
      Error!
      <JnJButton
        variant="contained"
        color="primary"
        size="small"
        onClick={props.retry}>
        Retry
      </JnJButton>
    </div>;
  } else if (props.timedOut) {
    return <div className={props.className}>
      Taking a long time...
      <JnJButton
        variant="contained"
        color="secondary"
        size="small"
        onClick={props.retry}>
        Retry
      </JnJButton>
    </div>;
  } else if (props.pastDelay) {
    return <div className={props.className}>Loading...</div>;
  } else {
    return null;
  }
};

export default Loading;
