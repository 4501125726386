import { createAction, createAsyncAction } from 'typesafe-actions';
import { LoginData } from './reducer';
import { LoginResponse, SignUpResponse, forgotPasswordResponse, newPasswordResponse } from '../../services/auth';

export const LOGIN_FORM = 'login'
export const SIGN_UP_FORM = 'sign-up'
export const FORGOT_PASSWORD_FORM = 'forgotPassword'
export const NEW_PASSWORD_FORM = 'newPasswordForm'

export type LoginInput = {
  email: string;
  password: string;
  recaptcha: string;
};

export type SignUpInput = {
  email: string;
  password: string;
  recaptcha: string;
};

export type forgotPasswordInput = {
  email: string;
  recaptcha: string;
}

export type newPasswordInput = {
  password: string;
}

export const restoreAuth = createAction('@auth/RESTORE_AUTH');
export const clearRegisterStatus = createAction('@auth/CLEAR_REGISTER_STATUS');

export const login = createAsyncAction(
  '@auth/LOGIN',
  '@auth/LOGIN_SUCCESS',
  '@auth/LOGIN_FAIL'
)<LoginInput, LoginResponse, string>();

export const signUp = createAsyncAction(
  '@auth/SIGN_UP',
  '@auth/SIGN_UP_SUCCESS',
  '@auth/SIGN_UP_FAIL'
)<SignUpInput, SignUpResponse, string>();

export const forgotPassword = createAsyncAction(
  '@auth/FORGOT_PASSWORD',
  '@auth/FORGOT_PASSWORD_SUCCESS',
  '@auth/FORGOT_PASSWORD_FAIL'
)<forgotPasswordInput, forgotPasswordResponse, string>();

export const setNewPassword = createAsyncAction(
  '@auth/SET_NEW_PASSWORD',
  '@auth/SET_NEW_PASSWORD_SUCCESS',
  '@auth/SET_NEW_PASSWORD_FAIL'
)<newPasswordInput, newPasswordResponse, string>();

export const logout = createAction('@auth/LOGOUT');

export const fetchLogin = createAsyncAction(
  '@@header/FETCH_LOGIN_REQUEST',
  '@@header/FETCH_LOGIN_SUCCESS',
  '@@header/FETCH_LOGIN_FAIL'
)<void, LoginData, string>();

export const cleanUp = createAction('@auth/CLEANUP');
