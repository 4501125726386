import { AxiosInstance, AxiosResponse } from "axios";
import { BlockDTO, BlocksResponseDTO } from "./types";
import { IAdvantagesBlock } from "../../type";

type AdvantagesBlockDTO = BlockDTO<'node--component_simple', {
  field_header_rich: {
    processed: string,
  }
}>;

type FieldComponentSimpleParagraphDTO = BlockDTO<'paragraph--component_block_description', {
  field_component_title: string
}>

function parseAdvantagesBlock(response: AxiosResponse<BlocksResponseDTO<AdvantagesBlockDTO,
  FieldComponentSimpleParagraphDTO>>): IAdvantagesBlock {
  const {
    data: [ blockData ],
    included: paragraphsData,
   } = response.data;
  return {
    type: 'AdvantagesBlock',
    data: {
      title: blockData.attributes.field_header_rich.processed,
      list: paragraphsData.map(({attributes}) => (
        attributes.field_component_title
      )),
    }
  };
}

export async function fetchAdvantagesBlock(axiosInstance: AxiosInstance, pageId: string): Promise<IAdvantagesBlock> {
  const blockResponse = await axiosInstance.
  get<BlocksResponseDTO<AdvantagesBlockDTO, FieldComponentSimpleParagraphDTO>>('/node/component_simple', {
    params: {
      filter: {
        'field_component_page': pageId,
      },
      include:[
        'field_component_simple_paragraph',
      ].join()
    },
  });

  return parseAdvantagesBlock(blockResponse);
}
