import { AxiosInstance, AxiosResponse } from "axios";
import { BlocksResponseDTO, BlockDTO, ImageBlockDTO } from "./types";
import { ComponentWithHeading } from "../../type";
import { requestUrl } from "../api";


type HeadingBlockDTO = BlockDTO<'node--component_simple', {
  field_header_rich: {
    value: string
  }
  field_component_description: {
    processed: string,
  }
}>;

type FieldComponentSimpleParagraphDTO = BlockDTO<'paragraph--component_items_list', {
  field_component_items: string[];
  field_component_title: string;
}, {
  field_component_image: {
    data: {
      id: string
    }
  }
}>

function parseGlobalCommitmentBlock
(response: AxiosResponse<BlocksResponseDTO<HeadingBlockDTO,
  FieldComponentSimpleParagraphDTO | ImageBlockDTO>>): ComponentWithHeading {
  const {
    data: [ blockData ],
    included: paragraphsData,
   } = response.data;

   type AgrType = {
    imageMap: Record<string, ImageBlockDTO>;
    paragraphsBlocks: FieldComponentSimpleParagraphDTO[];
  };

  const {
    imageMap = {},
    paragraphsBlocks = [],
   }: AgrType = paragraphsData.reduce((agr: AgrType, record) => {
      switch(record.type) {
        case 'file--file':
          return {
            ...agr,
            imageMap: {
              ...agr.imageMap,
              [record.id]: record,
            },
          };
        case 'paragraph--component_items_list':
          return {
            ...agr,
            paragraphsBlocks: [
              ...agr.paragraphsBlocks,
              record,
            ],
          };
      }
    },
    {
      imageMap: {},
      paragraphsBlocks: [],
    }
  );

  return {
    type: 'ComponentWithHeading',
    data: {
      title: blockData.attributes.field_header_rich.value,
      description: blockData.attributes.field_component_description.processed,
    },
    component: {
      type: 'HealthyBlock',
      data: {
        items: paragraphsBlocks.map(((item) => ({
          title: item.attributes.field_component_title,
          list: item.attributes.field_component_items,
          icon: requestUrl + imageMap[item.relationships.field_component_image.data.id].attributes.uri.url,
        }))),
      }
    }
  };
}

export async function fetchGlobalCommitmentBlock
(axiosInstance: AxiosInstance, pageId: string): Promise<ComponentWithHeading> {
  const blockResponse = await axiosInstance.
  get<BlocksResponseDTO<HeadingBlockDTO,
  FieldComponentSimpleParagraphDTO | ImageBlockDTO>>('/node/component_simple', {
    params: {
      filter: {
        'field_component_page': pageId,
      },
      include: [
        'field_component_simple_paragraph',
        'field_component_simple_paragraph.field_component_image',
      ].join(),
    },
  });

  return parseGlobalCommitmentBlock(blockResponse);
}
