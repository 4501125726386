import React from 'react'
import { connect } from 'react-redux';
import { RootState } from '../../store';
import { ResponseComponent } from '../../components/ResponsePageComponent';

export interface Props {
  responseData: any,
  isLoading: boolean
}

class RegistrationUserVerSuccess extends React.Component<Props> {
  render() {
    return !this.props.isLoading &&
      <ResponseComponent
        data={this.props.responseData}
        linkHref='/login/form'
      />
  }
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.auth.loading,
  responseData: state.auth.registrationResponseUserVerSuccess,
});

export default connect(mapStateToProps)(RegistrationUserVerSuccess);
