import * as React from 'react';
import { createStyles, WithStyles, withStyles, Theme } from '@material-ui/core/styles';
import {
  JnJButton,
  Typography,
  JnJWelContainer,
  JnJWelGridRow,
  JnJWelGridColumn,
} from '../../jnj-ui-components';
import { Link } from 'react-router-dom';
import { ErrorPageData } from '../../services/fetchBlock/fetchNotFoundBlock';
import { LocationDescriptor } from 'history';

const styles = (theme: Theme) => createStyles({
  root: {
    backgroundColor: '#fff',
    flex: 1,
  },
  container: {
    height: 'calc(100vh - 100px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  heading: {
    fontSize: 40,
    lineHeight: '44px',
    marginBottom: 10,
    marginTop: 'auto',
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      fontSize: 80,
      lineHeight: 1.05,
    },
    [theme.breakpoints.up('xl')]: {
      lineHeight: 1.25,
    },
  },
  description: {
    lineHeight: 1.56,
    [theme.breakpoints.up('md')]: {
      marginBottom: 58,
      fontSize: 28,
      lineHeight: 1.32,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 40,
      lineHeight: 1.2,
    },
  },
  button: {
    marginTop: 'auto',
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      width: 150,
    },
  }
});
interface ErrorPageProps {
  data: ErrorPageData;
  redirectLink?: LocationDescriptor;
}

const ErrorPage: React.SFC<ErrorPageProps & WithStyles<typeof styles>> = (props) => {
  const { classes, redirectLink = '/' } = props;
  return (
    <div className={classes.root}>
    <JnJWelContainer>
      <JnJWelGridRow>
      <JnJWelGridColumn xs={0} md={1} xl={2} />
        <JnJWelGridColumn xs={4} md={6} xl={6} className={classes.container}>
          <Typography variant="h1" color="primary" className={classes.heading}>
            {props.data.title}
          </Typography>

          <Typography variant="body1" className={classes.description}>
            {props.data.description}
          </Typography>
          {props.data.ctaLabel &&
            <JnJButton
              className={classes.button}
              variant="contained"
              color="primary"
              component={Link as any}
              {...{to: redirectLink}}
            >
              {props.data.ctaLabel}
            </JnJButton>
          }
        </JnJWelGridColumn>
      </JnJWelGridRow>
    </JnJWelContainer>
    </div>
  );
};

export default withStyles(styles)(ErrorPage) as React.SFC<ErrorPageProps>;
