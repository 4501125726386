import {Reducer} from 'redux';
import * as actions from './action';

type State =  {
  readonly errorData: actions.Error503 | null;
  readonly loading: boolean;
}


const initialState: State = {
  loading: true,
  errorData: null,
};

export const reducer: Reducer<State> = (state = initialState, action) => {
  switch(action.type) {
    case 'SHOW_503_ERROR':
      return {
        ...state,
        errorData: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
